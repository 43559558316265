<h1>Countries </h1>


<div *ngIf="countries">
  <div *ngIf="!countries">
    <p>No countries have been loaded</p>
  </div>
 
  <div >
    <table class='table-yara'>
      <thead>
        <tr>
          <th>Code</th>
          <th>Description</th>

        </tr>
      </thead>
      <tbody>
        <tr class="result-row" class="result-row" *ngFor="let country of countries; let i=index">
          <td>{{country.id}}</td>
          <td>{{country.name}}</td>

        </tr>
      </tbody>
    </table>
  </div>
</div>