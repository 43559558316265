<h1>Yara Products</h1>
<app-add-button *ngIf="!showDetails" (click)="add()"></app-add-button>
<div *ngIf="yaraFormulations">
  <div *ngIf="!yaraFormulations">
    <p>No data has been loaded</p>
  </div>
  <div *ngIf="showDetails">
    <h3 *ngIf="creating">Add a new Yara Product</h3>
    <h3 *ngIf="!creating">Edit Yara Product</h3>

    <div class="form-yara" [formGroup]="yaraProductForm">
<div class="form-group"><label for="">Formulation Code</label><input type="text" formControlName="formulationCode" /></div>
<div class="form-group"><label for="">Generic Name</label><input type="text" formControlName="genericName" /></div>
<div class="form-group"><label for="">Created</label><input type="text" bsDatepicker formControlName="created" /></div>
<div class="form-group"><label for="">Current</label><input type="checkbox" formControlName="current" /></div>
<div class="form-group"><label for="">Compat</label><input type="text" formControlName="compat" /></div>
<div class="form-group"><label for="">Last Update</label><input type="text" bsDatepicker formControlName="lastUpdate" /></div>
<div class="form-group"><label for="">Per Hectare</label><input type="text" formControlName="perHectare" /></div>
<div class="form-group"><label for="">Form</label><input type="text" formControlName="powderLiquid" /></div>
<div class="form-group"><label for="">DH Code</label><input type="text" formControlName="dHCode" /></div>
<div class="form-group"><label for="">Visible</label><input type="checkbox" formControlName="visible" /></div>
<div class="form-group"><label for="">Specification</label><input type="text" formControlName="specification" /></div>
<div class="form-group"><label for="">Unit Code</label><input type="text" formControlName="unitCode" /></div>
<div class="form-group"><label for="">Products</label><input type="text" formControlName="products" /></div>
<div class="form-group"><label for="">DH Code Description</label><input type="text" formControlName="dHCodeDescription" /></div>

    </div>
    

      <app-cancel-button (click)="cancel()"></app-cancel-button>
      <app-save-button (click)="save()"></app-save-button>

  </div>
  <div *ngIf="!showDetails">
    <table class="table-yara">
      <thead>
        <tr>
          <th>Code</th>
          <th>GenericName</th>
          <th>Created</th>
          <th>Current</th>

          <th>Compats Code</th>
          <th>LastUpdated</th>
          <th>Visible</th>
          <th>Form</th>
          <th>Products</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let yaraFormulation of yaraFormulations; let i = index">
          <td>{{ yaraFormulation.formulationCode }}</td>
          <td>{{ yaraFormulation.genericName }}</td>
          <td>{{ yaraFormulation.created | date: "dd-MMM-yyyy" }}</td>
          <td>
            <fa-icon
              icon="{{ yaraFormulation.current | tickCross }}"
              size="1x"
            ></fa-icon>
          </td>
          <td>{{ yaraFormulation.compat }}</td>

          <td>{{ yaraFormulation.visible | date: "dd-MMM-yyyy" }}</td>
          <td>
            <fa-icon
              icon="{{ yaraFormulation.current | tickCross }}"
              size="1x"
            ></fa-icon>
          </td>
          <td>
            {{ yaraFormulation.powderLiquid }}
          </td>
          <td>
            <div *ngFor="let productName of yaraFormulation.products">
              {{ productName }}
            </div>
          </td>
          <td>
            <app-edit-button (click)="edit(i)"></app-edit-button>
            <app-delete-button (click)="delete(i)"></app-delete-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
