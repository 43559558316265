import { Component, OnInit } from '@angular/core';
import { BackendAPIService } from '../../services/backend-api.service';
import { UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
@Component({
  selector: 'app-manufacturers',
  templateUrl: './manufacturers.component.html',
  styles: ['']
})
export class ManufacturersComponent implements OnInit {
  manufacturers: any[];
  initSub: Subscription;
  constructor(
    private apiService: BackendAPIService,
    private fb: UntypedFormBuilder,
    private router: Router

  ) { }



  ngOnInit() {
    this.initSub = this.apiService.getManufacturers().subscribe(data => this.manufacturers = data);
  }
  add() {
    this.router.navigateByUrl('/manufacturer/create');
  }
  edit(i: number) {
    this.router.navigateByUrl('/manufacturer/edit/'+this.manufacturers[i].manufacturerCode);
  }

}
