import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-add-button',
  template: `
<span  class="btn btn-primary btn-sm mb-2" >Add</span>
`, styles: []})
export class AddButtonComponent implements OnInit {  constructor() { }  ngOnInit(): void {  }}

@Component({
  selector: 'app-cancel-button',
  template: `
  <span  class="btn btn-primary btn-sm mb-2" >Cancel</span>
  `,  styles: [  ]
})
export class CancelButtonComponent implements OnInit {  constructor() { }  ngOnInit(): void {  }}

@Component({
  selector: 'app-delete-button',
  template: `<span  class="btn btn-primary btn-sm " >Delete</span>`,
  styles: [
  ]
})
export class DeleteButtonComponent implements OnInit {  constructor() { }  ngOnInit(): void {  }}
@Component({
  selector: 'app-edit-button',
  template: `<span  class="btn btn-primary btn-sm " >Edit</span>`,
  styles: [  ]
})
export class EditButtonComponent implements OnInit {  constructor() { }  ngOnInit(): void {  }}

@Component({
  selector: 'app-save-button',
  template: `
<span  class="btn btn-primary btn-sm mb-2" >Save</span>
`, styles: []})
export class SaveButtonComponent implements OnInit {  constructor() { }  ngOnInit(): void {  }}


@Component({
  selector: 'app-clear-button',
  template: `
<span  class="btn btn-primary btn-sm mb-2" >Clear</span>
`, styles: []})
export class ClearButtonComponent implements OnInit {  constructor() { }  ngOnInit(): void {  }}

