<h1>Upload Tankmixes from Excel</h1>
<form class="form-yara" [formGroup]='uploadForm'>
  <div class="form-group">
    <label>File Format</label>
    <app-nameid-select class="hideFromPrint" name='selectedFormat' [items]="uploadFormats"
     formControlName="selectedFormat">
    </app-nameid-select>
  </div>
  <div *ngIf="this.uploadForm.value.selectedFormat" class="form-group">
    <label for="Files"></label>
    <input type="file" #file style="display: none" (change)="onFilesAdded()" multiple />
    <span *ngIf="this.uploadForm.value.selectedFormat!=null && !uploads.length" class="btn btn-success btn-sm" color="primary"
      (click)="addFiles()">
      Select Files
    </span>
  </div>


</form>

<div *ngFor="let  upload of uploads; index as fileindex">
  <h3>{{upload.file.name}}</h3>
  <table class="two-column">
    <tbody>
      <tr>
        <th>Filename</th>
        <td>{{upload.file.name}}</td>
      </tr>
      <tr>
        <th>Format</th>
        <td>{{upload.formatName}}</td>
      </tr>
      <tr>
        <th>Status</th>
        <td>{{upload.status}}</td>
      </tr>
      <tr *ngIf="upload.processingData && upload.processingData.faults.length">
        <th>Faults</th>
        <td>
          <table class='faultTable'>
            <ng-container *ngFor="let fault of upload.processingData.faults">
              <tr>
                <th class='{{fault.category | faultCategory }}'>{{fault.category | faultCategory }}</th>
                <td>{{fault.detail}}</td>
              </tr>
            </ng-container>
          </table>
        </td>

      </tr>
    </tbody>
  </table>

  <div class='progress' *ngIf="upload.status=='Uploading'">
    <div class="progress-bar bg-primary" role="progressbar"
      [attr.style]="upload.uploadProgress | async | widthSylePercent">{{upload.uploadProgress| async}}%</div>
    <div>
    </div>
  </div>
</div>
<span *ngIf="this.uploadForm.value.selectedFormat!=null && uploads.length" class="btn btn-sm btn-danger" (click)='startAgain()'>Start
  Again</span>
<span *ngIf="this.uploadForm.value.selectedFormat!=null && uploads.length" class="btn btn-sm btn-primary" (click)='resubmit()'>Resubmit</span>