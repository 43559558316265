import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription, debounceTime, forkJoin } from 'rxjs';
import { YaraProduct } from 'src/app/models/master-data/YaraProduct';
import { NameId } from 'src/app/models/name-id';
import { BackendAPIService } from 'src/app/services/backend-api.service';

@Component({
  selector: 'app-yara-products',
  templateUrl: './yara-products.component.html',
  styles: [
  ]
})
export class YaraProductsComponent implements OnInit {
  yaraProducts: YaraProduct[];
  countries: NameId[] = [];
  filteredYaraProducts: YaraProduct[];
  initSub: Subscription;
  filterSub: Subscription;
  filterForm: FormGroup;
  constructor(
    private apiService: BackendAPIService,
    private fb: UntypedFormBuilder,
    private router: Router

  ) {
    this.filterForm = this.fb.group({
      genericNameSearchText: [null],
      productNameSearchText: [null],
      chemicalId: [null],
      dhCode: [null],

      countries: [null],
      isYaraVita: [null],
      visible: [null],

    });
    this.filterSub = this.filterForm.valueChanges.pipe(debounceTime(300)).subscribe((values:any) => {
      console.log('Filter Values', values)
      values.genericNameSearchText = this.cleanLower( values.genericNameSearchText);
      values.productNameSearchText = this.cleanLower(values.productNameSearchText);
      values.dhCode = this.cleanLower(values.dhCode);
      console.log(values.dhCode);
      values.chemicalId = this.cleanLower(values.chemicalId);
      this.filteredYaraProducts = this.yaraProducts.filter(x =>

        (values.genericNameSearchText == '' || (x.genericName || '').toLowerCase().includes(values.genericNameSearchText))
        && (values.productNameSearchText == '' || (x.productName || '').toLowerCase().includes(values.productNameSearchText))
        && (values.dhCode == '' || (x.dhCode || '').toLowerCase()==values.dhCode)
        && (values.chemicalId == '' || (x.chemicalId || '').toLowerCase()==values.chemicalId)
        && (values.countries.length == 0 || (values.countries.includes(x.countryId)))
      );
    }
    );
  }
  private cleanLower(inp?:string): string
  {
    return inp?.toLowerCase().trim() || ''
  }
  clearFilter() {
    this.filterForm.patchValue({
      genericNameSearchText: null,
      productNameSearchText: null,
      powerbrandSearchText: null,
      chemicalId: null,
      dhCode: null,
      isYaraVita: null,
      visible: null,
      countries: null
    });
    this.filteredYaraProducts = this.yaraProducts;
  }

  ngOnInit() {
    this.initSub = forkJoin([
      this.apiService.getYaraProducts({ visible: true }),
      this.apiService.getUsersCountries()
    ])
      .subscribe(([products, countries]) => {
        this.yaraProducts = products;
        this.countries = countries;
        this.filteredYaraProducts = products;
        this.filterForm.patchValue({ countries: countries.map(x => x.id) })
      });
  }
  add() {
    this.router.navigateByUrl('/yara-product/create');
  }
  edit(i: number) {
    console.log(i);
    this.router.navigateByUrl('/yara-product/edit/' + i);
  }
}
