import { Injectable } from '@angular/core';
import { concatMap, Observable, of, Subject, Subscription, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PushNotificationModel, PushNotificationModel2 } from '../models/PushNotificationModel';
import { HttpClient } from '@angular/common/http';
import { SwPush } from '@angular/service-worker';
import { APIPortResolver } from 'src/environments/APIPortResolver';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  incomingNotificationsSub: Subscription;
  sendNotificationToSelf(arg0: { someData: string; }) {
    throw new Error('Method not implemented.');
  }
  pushNotificationsSubscriptionResponse: any;
  pushIsSupported: boolean = 'serviceWorker' in navigator && 'PushManager' in window;
  addPushSubscriber(sub: any): Observable<void> {
    this.pushNotificationsSubscriptionResponse = sub;
    console.info('We have just subscribed to push notifications', sub)
    return of();
  }
  constructor(
    private http: HttpClient, private swPush: SwPush) {
    this.LatestNotification = new Subject<any>();

  }
  public LatestNotification: Subject<any>;
  public async subscribeToPush() {
    if (!this.pushIsSupported) {
      console.warn("Push notifications are NOT supported");
      return;
    }
    console.info("Push notifications  supported");
    try {
      const sub: PushSubscription = await this.swPush.requestSubscription({
        serverPublicKey: environment.pushNotificationsPublicKey,
      });
      console.info('Push Notification Subscription', sub);
      const sub2
        = new PushNotificationModel2(sub.getKey('p256dh'), sub.endpoint, sub.getKey('auth'));

      this.incomingNotificationsSub = this.http.post(APIPortResolver.resolve() + 'notifications/push-subscribe', sub2)
        .pipe(
          tap(response => {
            console.log("Sent details of push notifications to back end")
          }),
          concatMap(
            data => {
            console.debug("listening to clicks")
            return this.swPush.messages
          }
          )
          ,
          tap((data: any) => console.log('push message', data))
        ).subscribe();
    } catch (err) {
      console.error('Could not subscribe due to:', err);
    }
  }
  /*
   subscribeToPushNotifications() {
     if (!this.pushIsSupported) {
       console.warn("Push notifications are NOT supported");
       return;
     }
     console.info("Push notifications  supported");
     navigator.serviceWorker.ready
       .then(registration => {
         console.info("Service worker ready")
         registration.pushManager.getSubscription()
           .then(subscription => {
             console.debug('pushManager getSubscripition returned', subscription)
             if (subscription) {
               console.info("notifications subscription present, no need to register subscription again")
               return;
             }
             return registration.pushManager.subscribe({
               userVisibleOnly: true,
               applicationServerKey: this.urlBase64ToUint8Array(environment.pushNotificationsPublicKey)
             })
               .then(subscription => {
                 const rawKey = subscription.getKey ? subscription.getKey('p256dh') : '';
                 const key = rawKey ? btoa(String.fromCharCode.apply(null, new Uint8Array(rawKey))) : '';
                 const rawAuthSecret = subscription.getKey ? subscription.getKey('auth') : '';
                 const authSecret = rawAuthSecret ? btoa(String.fromCharCode.apply(null, new Uint8Array(rawAuthSecret))) : '';
                 const endpoint = subscription.endpoint;
                 const pushNotificationSubscription = new PushNotificationModel(key, endpoint, authSecret);
                 this.http.post(environment.apiUrl + 'notifications/push-subscribe', pushNotificationSubscription)
                   .subscribe(response => {
                     console.log("Tried to store push notification subscription", response)
                   })
               });
           });
       })
 
   }
   private urlBase64ToUint8Array(base64String) {
     const padding = '='.repeat((4 - base64String.length % 4) % 4);
     const base64 = (base64String + padding)
       .replace(/\-/g, '+')
       .replace(/_/g, '/');
     const rawData = window.atob(base64);
     const outputArray = new Uint8Array(rawData.length);
     for (let i = 0; i < rawData.length; ++i) {
       outputArray[i] = rawData.charCodeAt(i);
     }
     return outputArray;
   }
 
   */
}
