import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { NameId } from 'src/app/models/name-id';
import { Observable, of, noop } from 'rxjs';
import { debounceTime, distinctUntilChanged, catchError, map } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
@Component({
	selector: 'app-nameid-typeahead',
	template: `
<input 
	[ngModel]="inputValue" 
	adaptivePosition="true"
    [typeahead]="items"
    typeaheadOptionField="name"
	(typeaheadOnSelect)="selectItem($event)"
	itemTemplate='kkk'
	[placeholder]="placeholder"
	class="app-nameid-typeahead-input" 
	[disabled]="isDisabled"
	>

<span *ngIf='showClear' class='button button-primary button-s' (click)='erase()'>Clear</span>
  `,
	styles: [`
        :host {
        display:inline-block;
        input {}
        &.hidden input { width: 0; border-style: none;}}
        table.table-sample{  input{  border-style:none;}}
		`],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef(() => NameIdTypeaheadComponent),
	}]
})
export class NameIdTypeaheadComponent
	implements OnInit, ControlValueAccessor {
	public selectedItemLabel: string;
	@Input() placeholder = '';
	@Input() showBorder = true;
	@Input() showClear = false;
	@Input() showId = true;
	@Input() inputValue: string;
	public selectedItem: NameId;
	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;
	@Input() items: NameId[] = [];
	@Input() searchText: string;
	@Output() searchTextChange = new EventEmitter();
	isDisabled:boolean=false;
	constructor() { }
	ngOnInit() {

	}
	selectItem(ev) {
		console.log(ev.item)
		this.value = ev.item;
		this.selectedItemLabel = ev.item.name;
	}
	changeSearchText(ev) {
		const emer: NameId = {
			id: '',
			name: ev.target.value
		};
		this.selectedItem = emer;
		this.searchText = ev.target.value;
		this.searchTextChange.emit(ev.target.value);
	}
	@Input()
	nameFormatter(x: NameId) {
		if (x == undefined) { return ''; }
		if (x.id === undefined) { return x.name; }
		return x.name;
	}


	debounceSearchForItems = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			map(term => {
				if (term == '') { return []; }
				const uterms = term.toUpperCase().trim().split(' ');
				return this.items.filter(x => {
					for (const uterm of uterms) {
						if (!x.name.toUpperCase().includes(uterm)) {
							return false;
						}
					}
					return true;
				}
				).slice(0, 40);
			},
				catchError((e) => {
					return of([]);
				})

			)
		)

	get value(): any {
		return this.selectedItem;
	}

	set value(v: NameId) {
		if (v !== this.selectedItem) {
			this.selectedItem = v;
			this.selectedItemLabel = v.name;
			this.searchText = this.nameFormatter(v);
			this.onChangeCallback(v);
		}
	}
	erase() {
		this.value = null;// { id: '', name: '' };
	}
	onBlur() { this.onTouchedCallback(); }
	writeValue(value: NameId): void {

		if (value !== this.selectedItem) {
			this.selectedItem = value;
			this.selectedItemLabel = value?.name;
			this.searchText = this.nameFormatter(value);
		}
	}
	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}
	registerOnTouched(fn: any) { this.onTouchedCallback = fn; }

	setDisabledState?(isDisabled: boolean): void {
		this.isDisabled=isDisabled;
	}
}
