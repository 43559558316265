import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { add, addMonths } from 'date-fns';
import { Subscription, forkJoin, switchMap, tap, debounceTime } from 'rxjs';
import { NameId } from 'src/app/models/name-id';
import { RequestDetail } from 'src/app/models/RequestDetail';
import { BackendAPIService } from 'src/app/services/backend-api.service';


@Component({
  selector: 'app-request-search',
  templateUrl: './request-search.component.html',
  styles: [
  ]
})
export class RequestSearchComponent implements OnInit, OnDestroy {
  initSub: Subscription;
  searchSub: Subscription;
  searchCriteriaForm: FormGroup;
  results: RequestDetail[];
  requestStatusChoices: NameId[];
  constructor(private api: BackendAPIService, private fb: FormBuilder, private router: Router) {
    let today: Date | undefined = new Date();
    let cutoff: Date = addMonths(today, -1)

    this.searchCriteriaForm = this.fb.group({
      ingredient: [null],
      kind: [null],
      fromDate: [cutoff],
      untilDate: [today],
      requestor: [null],
      status: [null]

    });


    this.initSub = forkJoin([this.api.getRequestStatusChoices()])
      .pipe(tap(([requestStatusChoices]) => {
        this.requestStatusChoices = requestStatusChoices;

      }))
      .subscribe();
    this.searchSub = this.searchCriteriaForm.valueChanges
      .pipe(
        debounceTime(300),
        switchMap(criteria => { 
          if (criteria.status)
          {
            criteria.status=criteria.status.id;
          }
          return this.api.searchRequests(criteria);})
      ).subscribe()
  }
  ngOnDestroy(): void {
    this.searchSub.unsubscribe();
    this.initSub.unsubscribe();
  }

  ngOnInit(): void {
  }
  view(index: number) {
    this.router.navigateByUrl('/request/detail/' + this.results[index].requestDetailId);
  }
}
