import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';
import { NameIdSelected } from '../models/name-id';

@Component({
  selector: 'app-multi-select-name-id',
  templateUrl: './multi-select-name-id.component.html',
  styles: [
  ],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => MultiSelectNameIdComponent),
  }]
})
export class MultiSelectNameIdComponent implements OnInit, ControlValueAccessor {
  @Input()
  items: NameIdSelected[] = [];
  summary: string = '';
  showItems: boolean = false;
  selectedItemIds: string[] = [];
  isDisabled:boolean=false;
  constructor() { }
  
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any) { this.onTouchedCallback = fn; }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled=isDisabled;
  }

  ngOnInit(): void {
  }
  showChooser() {
    this.showItems = true;
  }
  hideChooser() {
    this.showItems = false;
    var selectedIds = this.items.filter(x => x.selected).map(x => x.id);
    this.writeValue(selectedIds);
  }
  toggleChooser() {
    this.showItems = !this.showItems;
  }
  allChanged(evt: any) {
    this.items.forEach(x => x.selected = evt.target.checked)
  }
  get value(): any {
    return this.selectedItemIds;
  }

  set value(v: any) {
   // if (v != this.selectedItemIds) {
      this.selectedItemIds = v;
      this.onChangeCallback(v);
   // }
  }
  writeValue(value: string[]): void {
    console.log("Writing Value from multi select", value)
    this.value = value;
    this.summary = ` ${value?.length||0} Countries`;
  }

}
