import { Component, OnInit } from "@angular/core";
import { NameId } from "../../models/name-id";
import { BackendAPIService } from "src/app/services/backend-api.service";
import { SelectionDataService } from "src/app/services/selection-data.service";
import {
  AbstractControl,
  FormArray,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Subscription, forkJoin, tap, mergeAll, combineLatest } from "rxjs";
import { TankmixChangeDetailsDTO } from "src/app/models/TankmixDetails";
import { Router } from "@angular/router";
import { PreferencesService } from "src/app/services/preferences.service";

@Component({
  selector: "app-data-entry-single",
  templateUrl: "./data-entry-single.component.html",
  styles: [``],
})
export class DataEntrySingle implements OnInit {
  model: any;
  showDetails = true;
  testBasisChoices: NameId[] = [
    {id:'F',name:'Foliar'},
    {id:'S',name:'Seed Treatment'}
  ];
  chemistChoices: NameId[] = [];
  countryChoices: NameId[] = [];
  agrochemicals: NameId[] = [];
  resultChoices: NameId[] = [];
  newTankmixForm: UntypedFormGroup;
  initSub: Subscription;
  constructor(
    private api: BackendAPIService,
    private selectionService: SelectionDataService,
    private preferencesService: PreferencesService,
    private fb: UntypedFormBuilder,
    private router: Router
  ) {
   
    this.newTankmixForm = this.fb.group(
      {
        country: [null, Validators.required],
        testBasis: [{id:'F',name:'Foliar'}, Validators.required],
        dateOfTest: [new Date(), Validators.required],
        waterRate: [null, Validators.required],
        result: [null, Validators.required],
        chemist: [null, Validators.required],
        observations: [null],
        ingredients: this.fb.array([
          this.fb.group({
            rate: [null, Validators.required],
            agrochemical: [null, Validators.required],
          }),
        ]),
      },
      { validator: this.checkEntireForm }
    );

    this.initSub = combineLatest([
      this.selectionService.chemists$,
      this.selectionService.countries$,
      this.selectionService.agrochemicals$,
      this.selectionService.results$,
    ])
      .pipe(
        tap(([chemists, countries, agrochemicals, resultChoices]) => {
          this.chemistChoices = chemists;
          this.countryChoices = countries;
          this.agrochemicals = agrochemicals;
          this.resultChoices = resultChoices;
        })
      )
      .subscribe();
    this.addProduct();
    this.newTankmixForm.valueChanges.subscribe((values) => {
      this.preferencesService.chemist = values.chemist;
    });
    this.preferencesService.chemist$
    .pipe(
      tap((chemist) => this.newTankmixForm.patchValue({ chemist: chemist }))
    )
    .subscribe();
  }

  checkEntireForm(control: AbstractControl): ValidationErrors | null {
    const ingredients = control.get("ingredients") as FormArray;
    if (ingredients.controls.length < 2) {
      return { numberOfIgredientsOK: false };
    }
    return null;
  }
  get ingredients() {
    return this.newTankmixForm.controls["ingredients"] as UntypedFormArray;
  }

  get formValues(): any {
    return this.newTankmixForm.value;
  }
  ngOnInit() {}

  addProduct() {
    this.ingredients.push(
      this.fb.group({
        rate: [null, Validators.required],
        agrochemical: [null, Validators.required],
      })
    );
  }
  deleteProduct(i: number) {
    this.ingredients.removeAt(i);
  }
  moveProductUp(i: number) {
    if (i > 0) {
      var temp = this.ingredients.controls[i - 1];
      this.ingredients.controls[i - 1] = this.ingredients.controls[i];
      this.ingredients.controls[i] = temp;
    }
  }
  moveProductDown(i: number) {
    if (i < this.ingredients.length - 1) {
      var temp = this.ingredients.controls[i + 1];
      this.ingredients.controls[i + 1] = this.ingredients.controls[i];
      this.ingredients.controls[i] = temp;
    }
  }
  save() {
    let newTankMix: TankmixChangeDetailsDTO = this.newTankmixForm.value;
    this.api
      .addNewTankmix(newTankMix)
      .subscribe((response) =>
        this.router.navigateByUrl("view-report/" + response.tankmixId)
      );
  }
}
