import { Pipe, PipeTransform } from '@angular/core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
@Pipe({
  name: 'tankmixResultTransform'
})
export class TankmixResultTransformPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    switch (value) {
      case 'C': return faCheck;
      case 'CA': return faCheck;
      case 'NC': return faTimes;
      default: return '';

    }
  }

}
