<h1>Yara Products</h1>
<app-add-button id="addYaraProduct" (click)="add()"></app-add-button>

<div class="filter" [formGroup]="filterForm">
  <div class="form-group">
    <label for="">Generic Name</label>
    <input id="genericName" type="text" formControlName="genericNameSearchText" />
  </div>
  <div class="form-group">
    <label for="">Market Name</label>
    <input id="productName" type="text" formControlName="productNameSearchText" />
  </div>

  <div class="form-group">
    <label for="">Countries</label>
    <app-multi-select-name-id [items]="countries" id="countries" formControlName="countries"></app-multi-select-name-id>
  </div>
  <div class="form-group">
    <label for="">ChemicalId</label>
    <input id="chemicalId" type="text" formControlName="chemicalId" />
  </div>

  <div class="form-group">
    <label for="">DH Code</label>
    <input id="dhCode" type="text" formControlName="dhCode" />
  </div>

  <app-clear-button id="filterClear" (click)="clearFilter()"></app-clear-button>
</div>


<div *ngIf="yaraProducts">
  <div *ngIf="!yaraProducts">
    <p>No product data has been loaded</p>
  </div>

  <div *ngIf="yaraProducts">
    <table class="table-yara">
      <thead>
        <tr>
          <th>Generic Name</th>
          <th>Product Name</th>
          <th>Country</th>
          <th>ChemicalId</th>
          <th>DH Code</th>
          <th>Form</th>
          <th>Visble</th>
          <th>Is Yara Vita</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr class="result-row" *ngFor="let yaraProduct of filteredYaraProducts; let i = index">
          <td>{{ yaraProduct.genericName }}</td>
          <td>{{ yaraProduct.productName }}</td>
          <td>{{ yaraProduct.countryId }}</td>
          <td>{{ yaraProduct.chemicalId }}</td>
          <td>{{ yaraProduct.dhCode }}</td>
          <td>{{ yaraProduct.form }}</td>
          <td>
            <fa-icon icon="{{ yaraProduct.visible | tickCross }}" size="1x"></fa-icon>
          </td>
          <td>
            <fa-icon icon="{{ yaraProduct.isYaraVita | tickCross }}" size="1x"></fa-icon>
          </td>

          <td>
            <app-edit-button (click)="edit(yaraProduct.yaraRegionalProductId)" [title]="'Edit Product '+yaraProduct.yaraRegionalProductId"></app-edit-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>