import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'yara-logo-kg',
  template: `<svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" 
  
   x="0px" y="0px" width="164px" height="117px" viewBox="0 0 164 117"

  xml:space="preserve" id="svg2" version="1.1">
  <g [attr.transform]="'translate(0,0),scale(2.6),scale(0.2)'">

<path class="logo-drawing-fill" d="M137.4,0H10C4.5,0,0,4.5,0,10v127.6c0,5.5,4.5,10,10,10h127.4c5.5,0,10-4.5,10-10V10C147.4,4.5,142.9,0,137.4,0
	z M144.1,137.6c0,3.6-3,6.5-6.5,6.5H10.1c-3.6,0-6.5-3-6.5-6.5V10c0-3.6,3-6.5,6.5-6.5h127.4c3.6,0,6.5,3,6.5,6.5L144.1,137.6
	L144.1,137.6z"/>
<path class="logo-background-fill" d="M144.1,137.6c0,3.6-3,6.5-6.5,6.5H10.1c-3.6,0-6.5-3-6.5-6.5V10c0-3.6,3-6.5,6.5-6.5h127.4c3.6,0,6.5,3,6.5,6.5
	L144.1,137.6L144.1,137.6z"/>
<g>
	<path class="logo-drawing-fill" d="M90.7,121c3.2-1.3,4.8-3.5,4.8-7.1c0-0.2,0-0.4,0-0.6c-0.3-4.8-4.1-7.1-8.6-7.1H74.8v24.2h5.8v-8.7h4.1
		l4.5,8.7h6.5L90.7,121z M85.9,117.6h-5.7v-7.1h5.7c2.2,0,3.5,1,3.5,3.5C89.6,116.4,88.3,117.6,85.9,117.6z"/>
	<path class="logo-drawing-fill" d="M43.9,106.3h-6.5l-4.1,10.6l-4.5-7.8c-0.9-1.4-3-2.7-4.8-2.7h-3.3l10,17.5l-2.6,6.8h3.5c1.7,0,3.6-1.3,4.2-2.9
		L43.9,106.3z"/>
	<path class="logo-drawing-fill" d="M61.3,119.9h-8.2l3.5-9.1c0.4-1,1-1,1.3,0L61.3,119.9z M62.8,124.1L62.8,124.1l2.5,6.5h6.1l-8.1-21.3
		c-0.6-1.6-2.6-2.9-4.2-2.9H52l-9.1,24.2h3c1.7,0,3.6-1.3,4.2-2.9l1.3-3.5h11.4L62.8,124.1L62.8,124.1z"/>
	<path class="logo-drawing-fill" d="M110.9,110.9c0.4-1,1-1,1.3,0l3.5,9.1h-8.2L110.9,110.9z M106,124.1h11.3l1.3,3.5c0.6,1.6,2.5,2.9,4.2,2.9h3
		l-9.3-24.2h-7c-1.7,0-3.6,1.3-4.2,2.9l-8.1,21.3h6.1L106,124.1L106,124.1L106,124.1z"/>
</g>
<g>
	<path class="logo-drawing-fill" d="M94.4,61.2c0.3,0.2,1,0.2,1.7,0.2c2.6,0,7.7,0,10.7,0c-5.4-4.8-8.7-16.7-8.7-23.8c0-7.3,3.3-19.1,8.7-23.8
		c-2.9,0-8,0-10.7,0c-0.6,0-1.4,0-1.7,0.2c0,0-9.6,9.1-9.6,23.6C84.8,52.1,94.4,61.2,94.4,61.2z"/>
	<path class="logo-drawing-fill" d="M71.3,61.2c0.3,0.2,1,0.2,1.7,0.2c2.6,0,7.7,0,10.6,0c-5.4-4.8-8.7-16.7-8.7-23.8c0-7.3,3.5-19.1,8.7-23.8
		c-2.9,0-8,0-10.6,0c-0.6,0-1.4,0-1.7,0.2c0,0-9.6,9.3-9.6,23.6C61.9,51.9,71.3,61.2,71.3,61.2z"/>
	<path class="logo-drawing-fill" d="M48.4,61.2c0.3,0.2,1,0.2,1.6,0.2c2.7,0,7.8,0,10.7,0C55.3,56.6,52,44.7,52,37.6c0-7.3,3.3-19.1,8.7-23.8
		c-2.9,0-8,0-10.7,0c-0.6,0-1.4,0-1.6,0.2c0,0-9.6,9.1-9.6,23.6C38.8,52.1,48.4,61.2,48.4,61.2z"/>
	<path class="logo-drawing-fill" d="M123.6,80.2c-1.6-3.3-4.5-9-4.9-10.9c0,0-1.7-4.3,2.9-5.1c1.9-0.3,3.2-1.7,3.3-3.5v-2.2c0-0.3-0.3-0.6-0.6-0.6
		h-7.5c-0.2,0-5.4,0.6-5.4,7c-0.2,10.9,0,12.2-2.9,12.8c-0.3,0-0.4-0.3-0.4-0.4l0,0c0-0.2,0-0.2,0-0.3c0-5.8-4.6-10.5-10.5-10.5
		c-2.9,0-5.7,1.3-7.4,3.2l0,0c0,0.2-0.3,0.3-0.4,0.3c-0.2,0-0.4-0.2-0.4-0.3c-1.9-1.9-4.5-3.2-7.4-3.2s-5.7,1.3-7.4,3.2l0,0
		c0,0.2-0.3,0.3-0.4,0.3c-0.2,0-0.4-0.2-0.4-0.3l0,0c-1.9-1.9-4.5-3.2-7.4-3.2s-5.7,1.3-7.4,3.2l0,0c0,0.2-0.3,0.3-0.4,0.3
		c-0.2,0-0.4-0.2-0.4-0.3l0,0c-1.9-1.9-4.5-3.2-7.4-3.2c-5.8,0-10.5,4.6-10.5,10.5c0,0.2,0,0.2,0,0.3l0,0c0,0.3-0.3,0.4-0.6,0.4
		c-2.9-0.4-2.7-1.9-2.9-12.8c0-6.4-5.2-7-5.4-7h-7.8c-0.3,0-0.6,0.3-0.6,0.6v2.2c0.2,1.9,1.3,3.3,3.3,3.5c4.6,0.7,2.9,5.1,2.9,5.1
		c-0.4,1.9-3.3,7.5-4.9,10.9c-1,2.2-3.2,7.4,0.2,12.6c1.9,3,4.2,5.7,12.3,5.7h75c8.1,0.2,10.5-2.6,12.3-5.7l0,0
		C126.7,87.7,124.7,82.3,123.6,80.2z M50,83.1c-3.5,0-6.2-2.7-6.2-6.2s2.7-6.2,6.2-6.2s6.2,2.7,6.2,6.2C56.3,80.3,53.3,83.1,50,83.1
		z M65.8,83.1c-3.5,0-6.2-2.7-6.2-6.2s2.7-6.2,6.2-6.2s6.2,2.7,6.2,6.2C72,80.3,69.3,83.1,65.8,83.1z M81.6,83.1
		c-3.5,0-6.2-2.7-6.2-6.2s2.7-6.2,6.2-6.2s6.2,2.7,6.2,6.2C87.8,80.3,85.1,83.1,81.6,83.1z M97.5,83.1c-3.5,0-6.2-2.7-6.2-6.2
		s2.7-6.2,6.2-6.2s6.2,2.7,6.2,6.2C103.8,80.3,101,83.1,97.5,83.1z"/>
</g>
<g>
	<path class="logo-lettering-fill" d="M25.2,217.7c0,0.3-0.3,0.4-0.4,0.4H19c-0.3,0-0.6-0.2-0.7-0.3l-7.8-10.5H8.9v10.1c0,0.3-0.3,0.4-0.6,0.4H3.8
		c-0.3,0-0.6-0.2-0.6-0.4v-24.3c0-0.3,0.3-0.4,0.6-0.4h4.6c0.3,0,0.6,0.2,0.6,0.4v10h1.6l7.5-10.3c0.2-0.2,0.3-0.3,0.6-0.3l0,0h5.5
		c0.2,0,0.4,0.3,0.4,0.4c0,0.2,0,0.2-0.2,0.3l-9.1,11.6L25,217C25.2,217.6,25.2,217.7,25.2,217.7z"/>
	<path class="logo-lettering-fill" d="M42.9,218.3h-4.2c-0.4,0-0.6-0.2-0.6-0.4V206c0-1.9-0.7-2.5-2.3-2.5s-3.5,0.4-3.5,0.4V218
		c0,0.3-0.2,0.4-0.6,0.4h-4.2c-0.4,0-0.6-0.2-0.6-0.4v-15.1c0-1.3,0.9-1.7,2.9-2.2c1.7-0.4,4.5-0.9,6.5-0.9c4.3,0,7,1,7,5.4v12.6
		C43.5,218.2,43.2,218.3,42.9,218.3z"/>
	<path class="logo-lettering-fill" d="M54.9,218.8c-7.8,0-8.7-4.2-8.7-9.4c0-5.4,1-9.6,8.7-9.6s8.7,4.2,8.7,9.6C63.8,214.5,62.8,218.8,54.9,218.8z
		 M54.9,203.2c-3.2,0-3.3,1.9-3.3,5.9s0.2,5.9,3.3,5.9c3.2,0,3.3-1.9,3.3-5.9C58.3,205.1,58.1,203.2,54.9,203.2z"/>
	<path class="logo-lettering-fill" d="M91,217.7c0,0.3-0.3,0.6-0.6,0.6h-7.3c-0.4,0-0.6-0.3-0.7-0.6l-2.3-12.8l0,0l-2.5,12.8c0,0.3-0.3,0.6-0.6,0.6
		h-7.3c-0.4,0-0.6-0.3-0.6-0.6L65.5,201v-0.2c0-0.4,0.2-0.7,0.6-0.7H70c0.4,0,0.7,0.2,0.9,0.6l2.3,14.2h0.9l2.2-13.3
		c0-0.4,0.3-0.6,0.7-0.6h6.5c0.4,0,0.6,0.2,0.7,0.6l2.3,13.3h0.9l2.2-14.2c0-0.4,0.4-0.6,0.7-0.6h3.8c0.4,0,0.7,0.3,0.7,0.7v0.2
		L91,217.7z"/>
	<path class="logo-lettering-fill" d="M106,217.6c0,0.4-0.3,0.4-0.4,0.4c-0.3,0.2-1.3,0.4-3.3,0.4c-3.5,0-5.2-2-5.2-4.8v-20c0-0.4,0.2-0.7,0.6-0.7
		l3.9-0.7h0.2c0.4,0,0.6,0.2,0.6,0.6v20.3c0,1.2,0.7,1.6,1.3,1.6c0.6,0,0.9,0,1.2-0.2c0,0,0,0,0.2,0c0.3,0,0.4,0.2,0.4,0.4l0.4,2.3
		L106,217.6L106,217.6z"/>
	<path class="logo-lettering-fill" d="M124.1,210.6c0,0.4-0.2,0.9-0.9,0.9H112c0,1.4,0.4,2.3,1.4,2.9c0.9,0.4,2,0.7,3.8,0.7c2.2,0,3.9-0.6,4.8-0.9
		c0,0,0,0,0.2,0c0.3,0,0.4,0.2,0.6,0.6l0.4,1.9c0,0.2,0,0.2,0,0.2c0,0.3-0.2,0.4-0.4,0.6c-1.2,0.4-3.5,1.2-6.4,1.2
		c-6.7,0-9.6-2.5-9.6-9.4c0-6.7,2-9.6,8.7-9.6c3.5,0,5.5,0.9,6.8,2.3c1.3,1.6,1.7,3.9,1.7,6.8C124.1,209.3,124.1,210.1,124.1,210.6z
		 M118.9,208c0-0.9,0-1.7-0.2-2.5c-0.4-1.7-1.3-2.3-3-2.3c-2.2,0-3,0.7-3.3,2.2c-0.2,0.9-0.2,1.7-0.2,2.7h6.8L118.9,208L118.9,208z"
		/>
	<path class="logo-lettering-fill" d="M141.5,217.9c-1.7,0.4-3.8,0.9-5.9,0.9c-6.8,0-9.1-2.2-9.1-9.3c0-5.9,1.4-9.6,7.4-9.6c2.5,0,4.3,0.6,4.6,0.6
		l-0.4-2.2V194c0-0.4,0.2-0.7,0.7-0.9l3.9-0.7h0.2c0.4,0,0.6,0.2,0.6,0.6v22.3C143.6,216.1,143.5,217.3,141.5,217.9z M138.3,203.7
		c0,0-1.4-0.3-2.7-0.3c-2.6,0-3.5,0.4-3.5,5.8c0,5.5,1,6.1,3.8,6.1c1.3,0,2.6-0.4,2.6-0.4v-11.2L138.3,203.7L138.3,203.7z"/>
	<path class="logo-lettering-fill" d="M165.4,202.8c0,0.2,0,0.3-0.3,0.4c-0.7,0.4-1.6,0.7-2.5,0.7c-0.6,0-0.7,0-1.4-0.2c0.6,0.4,1.4,1.4,1.4,3
		c0,4.2-3.3,5.7-7.4,5.7h-2c-1.2,0-1.6,0.6-1.6,1.4c0,1.2,0.9,1.3,2.6,1.3h3.3c3,0,6.4,0.7,6.4,4.8c0,4.3-3.8,6.1-9,6.1
		c-5.9,0-9-1-9-4.3c0-3.2,2.6-3.3,3.8-3.6v-0.6c-1.6-0.4-3.2-0.3-3.2-3c0-2.3,1.7-2.6,3.9-3c-1.2-0.3-3.9-1-3.9-5.2
		c0-4.9,3-6.5,8.1-6.5c2.2,0,3,0.3,4.5,0.7c0.4,0.2,0.9,0.2,1.3,0.2s1.2,0,1.7-0.2c0.6-0.2,1.3-0.4,1.7-0.7c0.2,0,0.3-0.2,0.3-0.2
		c0.2,0,0.3,0.2,0.4,0.4L165.4,202.8C165.2,202.6,165.4,202.8,165.4,202.8z M155.8,218.9h-2.3c-1.2,0-2.6,0.2-2.6,1.9
		s1.4,2.2,3.9,2.2c2.2,0,3.8-0.4,3.8-2.3C158.7,219,157.4,218.9,155.8,218.9z M154.7,202.6c-2.5,0-3,1.2-3,3.5s0.7,3.5,3,3.5
		s3-1.2,3-3.5S157,202.6,154.7,202.6z"/>
	<path class="logo-lettering-fill" d="M183.4,210.6c0,0.4-0.2,0.9-0.9,0.9h-11.2c0,1.4,0.4,2.3,1.4,2.9c0.9,0.4,2,0.7,3.8,0.7c2.2,0,3.9-0.6,4.8-0.9
		c0,0,0,0,0.2,0c0.3,0,0.4,0.2,0.6,0.6l0.4,1.9c0,0.2,0,0.2,0,0.2c0,0.3-0.2,0.4-0.4,0.6c-1.2,0.4-3.5,1.2-6.4,1.2
		c-6.7,0-9.6-2.5-9.6-9.4c0-6.7,2-9.6,8.7-9.6c3.5,0,5.5,0.9,6.8,2.3c1.3,1.6,1.7,3.9,1.7,6.8C183.4,209.3,183.4,210.1,183.4,210.6z
		 M178.1,208c0-0.9,0-1.7-0.2-2.5c-0.4-1.7-1.3-2.3-3-2.3c-2.2,0-3,0.7-3.3,2.2c-0.2,0.9-0.2,1.7-0.2,2.7h6.8L178.1,208L178.1,208z"
		/>
	<path class="logo-lettering-fill" d="M216.5,202.8c0,0.2,0,0.3-0.3,0.4c-0.7,0.4-1.6,0.7-2.5,0.7c-0.6,0-0.7,0-1.4-0.2c0.6,0.4,1.4,1.4,1.4,3
		c0,4.2-3.3,5.7-7.4,5.7h-2c-1.2,0-1.6,0.6-1.6,1.4c0,1.2,0.9,1.3,2.6,1.3h3.3c3,0,6.4,0.7,6.4,4.8c0,4.3-3.8,6.1-9,6.1
		c-5.9,0-9-1-9-4.3c0-3.2,2.6-3.3,3.8-3.6v-0.6c-1.6-0.4-3.2-0.3-3.2-3c0-2.3,1.7-2.6,3.9-3c-1.2-0.3-3.9-1-3.9-5.2
		c0-4.9,3-6.5,8.1-6.5c2.2,0,3,0.3,4.5,0.7c0.4,0.2,0.9,0.2,1.3,0.2s1.2,0,1.7-0.2c0.6-0.2,1.3-0.4,1.7-0.7c0.2,0,0.3-0.2,0.3-0.2
		c0.2,0,0.3,0.2,0.4,0.4L216.5,202.8C216.5,202.6,216.5,202.8,216.5,202.8z M207.1,218.9h-2.3c-1.2,0-2.6,0.2-2.6,1.9
		s1.4,2.2,3.9,2.2c2.2,0,3.8-0.4,3.8-2.3C209.9,219,208.7,218.9,207.1,218.9z M205.8,202.6c-2.5,0-3,1.2-3,3.5s0.7,3.5,3,3.5
		s3-1.2,3-3.5S208.3,202.6,205.8,202.6z"/>
	<path class="logo-lettering-fill" d="M228,203.7c-2,0-3.8,0.3-4.3,0.4v13.6c0,0.3-0.2,0.4-0.6,0.4h-4.2c-0.3,0-0.6-0.2-0.6-0.4v-15.1
		c0-1,0.4-1.3,1.6-1.7c1-0.4,2.6-0.6,4.2-0.9c1-0.2,2.2-0.3,3.3-0.4h0.3c0.4,0,0.4,0,0.7,0.2c0.2,0,0.3,0.3,0.3,0.6v2.5
		C228.6,203.4,228.6,203.7,228,203.7z"/>
	<path class="logo-lettering-fill" d="M238.6,218.8c-7.8,0-8.7-4.2-8.7-9.4c0-5.4,1-9.6,8.7-9.6s8.7,4.2,8.7,9.6
		C247.4,214.5,246.4,218.8,238.6,218.8z M238.6,203.2c-3.2,0-3.3,1.9-3.3,5.9s0.2,5.9,3.3,5.9c3.2,0,3.3-1.9,3.3-5.9
		C241.9,205.1,241.8,203.2,238.6,203.2z"/>
	<path class="logo-lettering-fill" d="M274.7,217.7c0,0.3-0.3,0.6-0.6,0.6h-7.3c-0.4,0-0.6-0.3-0.7-0.6l-2.3-12.8l0,0l-2.5,12.8
		c0,0.3-0.3,0.6-0.6,0.6h-7.2c-0.4,0-0.6-0.3-0.6-0.6l-3.6-16.7v-0.2c0-0.4,0.2-0.7,0.6-0.7h3.9c0.4,0,0.7,0.2,0.9,0.6l2.3,14.2h0.9
		l2.2-13.3c0-0.4,0.3-0.6,0.7-0.6h6.5c0.4,0,0.6,0.2,0.7,0.6l2.3,13.3h0.9l2-14.2c0-0.4,0.4-0.6,0.7-0.6h3.8c0.4,0,0.7,0.3,0.7,0.7
		v0.2L274.7,217.7z"/>
	<path class="logo-lettering-fill" d="M286.7,218.6c-3,0-5.8-0.7-6.4-1c-0.2,0-0.4-0.2-0.4-0.4V217l0.4-2.2c0-0.3,0.2-0.4,0.4-0.4h0.2
		c0.4,0.2,2.9,0.9,5.1,0.9c1.6,0,2.9-0.4,2.9-2c0-1.7-1-1.7-2.9-2.2c-2.3-0.4-5.9-0.9-5.9-5.7c0-5.1,4.5-5.7,7.4-5.7
		c2.7,0,4.9,0.6,5.7,0.9c0.3,0,0.4,0.3,0.4,0.4v0.2l-0.4,2.3c0,0.3-0.3,0.4-0.4,0.4c0,0,0,0-0.2,0c-0.4,0-2.5-0.4-4.2-0.4
		c-1.3,0-3,0.3-3,1.7c0,1.7,1.7,1.9,3.5,2.2c2.6,0.4,5.4,1.2,5.4,5.5C294.2,217.3,290.9,218.6,286.7,218.6z"/>
</g>


          </g>
</svg>`
})
export class YaraLogoKnowledgeGrowsComponent implements OnInit {
  @Input() size: number=1.0;

  constructor() { }

  ngOnInit() {
  }

}
