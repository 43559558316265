import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { environment_auth } from 'src/environments/environment_auth';

@NgModule({
    imports: [AuthModule.forRoot({
        config: {
            authority: 'https://login.microsoftonline.com/ef8a53ea-1a1c-4189-b792-c832dcaea568/v2.0',
            redirectUrl: window.location.origin,
            clientId: environment_auth.openIdConnectClientId,
            scope: 'openid profile offline_access email', 
            responseType: 'code',
            silentRenew: true,
            useRefreshToken: true,
            maxIdTokenIatOffsetAllowedInSeconds: 600,
            issValidationOff: false,
            logLevel: LogLevel.Warn,
            historyCleanupOff: false
, autoUserInfo:false
        }
    })],
    exports: [AuthModule],

})
export class AuthConfigModule { }
