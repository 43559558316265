import { Injectable } from '@angular/core';
import {  HttpInterceptor,  HttpRequest,  HttpHandler,  HttpResponse,   HttpErrorResponse} from '@angular/common/http';
import { finalize, tap, catchError } from 'rxjs/operators';
import { MonitorService } from '../services/monitor.service';
import { throwError } from 'rxjs';
import { ToastService } from '../services/toast.service';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  public logErrors = true;
  public logSuccess = true;
  constructor(private errorMonitor:MonitorService,private toastService:ToastService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const startTime = Date.now();
    let status: string;
    this.errorMonitor.startRequest();
    return next.handle(req).pipe(
      tap(
        event => {
          status = '';
          if (event instanceof HttpResponse) {
            status = 'succeeded';
          }
        },
        error => (status = 'failed')
      ), catchError((error: HttpErrorResponse) => {
        let data = {};
        data = {
          reason: error && error.error && error.error.reason ? error.error.reason : '',
          status: error.status
        };
        this.toastService.show('API Error '+error.status,error.url,{ classname: 'bg-danger text-light', delay: 15000 })
        return throwError(error);
      }), 
      finalize(() => {
        this.errorMonitor.finishRequest();
        const elapsedTime = Date.now() - startTime;
        const message =`${req.method} ${req.urlWithParams} ${status} ${elapsedTime}ms`;

        console.log(message);
      })
    );
  }
  
}
