<h2>Bulk Import From Network Folder</h2>
<p>
  This facility casues the server to scan a nominated folder on the network. If
  Standard format tankmix files are found, they are automatically imported
</p>

<span *ngIf="scanData.status == 'dormant'" class="btn btn-success" (click)="scanStart()">Start Scan</span>
<div *ngIf="scanData.status != 'dormant'">
  <div class="alert alert-primary" role="alert">
    <h2>{{ scanData.status }}</h2>
    <span *ngIf="scanData.status != 'Cancelled' && scanData.status != 'Completed'" class="btn btn-danger" (click)="scanStop()">Stop Scan</span>
  </div>

  <table *ngIf="scanData.status" class="table">
    <tr *ngIf="scanData.pathToFolder">
      <th>Folder Location</th>
      <td>{{ scanData.pathToFolder }}</td>
    </tr>
    <tr *ngIf="scanData.numFilesFound">
      <th>Files found in folder</th>
      <td>{{ scanData.numFilesFound }}</td>
    </tr>
    <tr *ngIf="scanData.filesExamined">
      <th>Files Examined</th>
      <td>{{ scanData.filesExamined }}</td>
    </tr>
  </table>
</div>
