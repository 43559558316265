<h1>Chemists</h1>
<app-add-button id="addChemist" (click)='add()'>
</app-add-button>
<div *ngIf="chemists">
  <div *ngIf="!chemists">
    <p>No tester data has been loaded</p>
  </div>

  <div >
    <table class='table-yara'>
      <thead>
        <tr>
          <th>Name</th>
          <th>User Id</th>
          <th>Roles</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr class="result-row" *ngFor="let chemist of chemists; let i=index">
          <td>{{chemist.name}}</td>
          <td>{{chemist.userId}}</td>
          <td>{{chemist.roles}}</td>
          <td>
            <app-edit-button  (click)='edit(i)'></app-edit-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>