import { Component, OnInit } from '@angular/core';
import { UserSettings } from './models/user-settings';
@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styles: ['']
})
export class UserSettingsComponent implements OnInit {
  mySettings: UserSettings = {
    defaultRegion: {
      regionName: 'UK Master',
      regionId: '01'
    },
    languages: 'en-gb'
  };
  constructor() { }

  ngOnInit() {
  }

}
