<div *ngIf="details">
<h1>Tankmix {{ details?.kind | titlecase }} Request Details</h1>

  <div class="form-yara">
    <div class="form-group">
      <label for="">Request No</label
      ><span class="readonlybox" id="requestDetailId">{{
        details.requestDetailId
      }}</span>
    </div>
  </div>
  <div class="form-yara">
    <div class="form-group">
      <label for="">Requestor</label
      ><span class="readonlybox" id="requestor">{{ details.requestor }}</span>
    </div>
  </div>
  <div class="form-yara">
    <div class="form-group">
      <label for="">Company</label
      ><span class="readonlybox" id="company">{{ details.company }}</span>
    </div>
  </div>
  <div class="form-yara">
    <div class="form-group">
      <label for="">Status</label
      ><span class="readonlybox" id="status">{{ details.status }}</span>
    </div>
  </div>
  <div class="form-yara">
    <div class="form-group">
      <label for="">Email</label
      ><span class="readonlybox" id="email">{{ details.email }}</span>
    </div>
  </div>
  <div class="form-yara">
    <div class="form-group">
      <label for="">Kind</label
      ><span class="readonlybox" id="kind">{{ details.kind }}</span>
    </div>
  </div>
  <div class="form-yara">
    <div class="form-group">
      <label for="">Created</label
      ><span class="readonlybox" id="dateCreated">{{
        details.dateCreated | date: "dd-MMM-yyyy"
      }}</span>
    </div>
  </div>


<h2>Ingredients</h2>
<p *ngIf="!details?.ingredients?.length">
  no ingredients were specified. This is a defect as the request could never be
  fulfilled
</p>
<table *ngIf="details?.ingredients?.length" id="ingredients" class="table-yara">
  <thead>
    <tr>
      <th>Order</th>
      <th>Name</th>
      <th>Rate</th>
      <th>Units</th>
      <th>Comment</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let ingredient of details?.ingredients" class="result-row">
      <td class="ordinal">{{ ingredient.ordinal }}</td>
      <td class="name">{{ ingredient.name }}</td>
      <td class="rate">{{ ingredient.rate }}</td>
      <td class="units">{{ ingredient.units }}</td>
      <td class="comment">{{ ingredient.comment }}</td>
    </tr>
  </tbody>
</table>

<h2>Comments</h2>

<p *ngIf="!details?.comments?.length">There are no comments</p>
<div class="form-yara" [formGroup]="commentForm">
  <div class="form-group">
    <label for="">New Comment</label
    ><textarea id="newComment" formControlName="newComment"></textarea>
  </div>
  <div class="form-group">
    <label for="">Status</label>
    <app-nameid-select
      id="status"
      name="status"
      [items]="statusChoices"
      formControlName="status"
    >
    </app-nameid-select>
  </div>
</div>
<app-add-button *ngIf="commentForm.valid" id="addComment" (click)="addComment()"
  >Add</app-add-button
>
<table *ngIf="details?.comments?.length" id="comments" class="table-yara">
  <thead>
    <tr>
      <th>At</th>
      <th>Comment</th>
      <th>By</th>
      <th>Status</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let comment of details.comments" class="result-row">
      <td class="dateCreated">
        {{ comment.dateCreated | date: "dd-MMM-yyyy" }}
      </td>
      <td class="comment">{{ comment.comment }}</td>
      <td class="addedUserId">{{ comment.addedUserId }}</td>
      <td class="status">{{ comment.status }}</td>
    </tr>
  </tbody>
</table>
</div>