<h1>Outstanding Requests</h1>
<p *ngIf="results && !results.length">There are no outstanding requests</p>
<table class="table-yara" *ngIf="results && results.length">
	<thead>
		<tr>
			<th>RequestId</th>
			<th>Created</th>
			<th>Requestor</th>
			<th>Company</th>
			<th>Country</th>
			<th>Kind</th>
			<th>Status</th>
			<th>Water</th>
			<th>Crop</th>
			<th>Ingredients</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let result of results;let i=index" class="result-row clickable" (click)="view(i)"  title="Click to update request">
			<td>{{result.requestDetailId}}</td>
			<td>{{result.dateCreated | date: "dd-MMM-yyyy"}}</td>
			<td>{{result.requestor}}</td>
			<td>{{result.company}}</td>
			<td>{{result.country}}</td>
			<td>{{result.kind}}</td>
			<td>{{result.status}}</td>
			<td>{{result.waterRate}}</td>
			<td>{{result.crop}}</td>
			
			<td>

				<table>
					<thead>
						<tr>
							<th>Order</th>
							<th>Chemical</th>
							<th>Amount</th>
							<th>Units</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let ingredient of result.ingredients; let i =index">
							<td>{{i+1}}</td>
							<td>{{ingredient.name}}</td>
							<td>{{ingredient.rate | number: '1.2-2'}}</td>
							<td>{{ingredient.units}}</td>
						</tr>
					</tbody>

				</table>

			</td>
		</tr>
	</tbody>
</table>