<h1>Chemical Categories</h1>
<app-add-button id='addChemicalCategory' *ngIf="!showDetails" (click)="add()"></app-add-button>
<div *ngIf="chemicalCategories">
  <div *ngIf="!chemicalCategories">
    <p>No chemical categories have been loaded</p>
  </div>

  <div *ngIf="!showDetails">
    <table class="table-yara">
      <thead>
        <tr>
          <th>Code</th>
          <th>Description</th>
          <th>Deactivated</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr  class="result-row" *ngFor="let chemicalCategory of chemicalCategories; let i = index">
          <td>{{ chemicalCategory.id }}</td>
          <td>{{ chemicalCategory.name }}</td>
          <td class="deactivated">{{chemicalCategory.deactivated | date:"dd-MMM-yyyy"}}</td>
          <td>
            <app-edit-button (click)="edit(i)"></app-edit-button>
           </td>
        </tr>
      </tbody>
    </table>
  </div>
  
</div>
