import { Component, OnInit } from '@angular/core';
import { BackendAPIService } from '../../services/backend-api.service';
import { Observable, Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styles: ['']
})
export class CountriesComponent implements OnInit {
  countries: any[];
  initSub: Subscription;
  showDetails = false;
  details: any = {};

  countryForm: UntypedFormGroup;
  constructor(private apiService: BackendAPIService, private fb: UntypedFormBuilder) {
    this.countryForm = this.fb.group(
      {
        countryCode: [null],
        name: [null]
      });
  }

  ngOnInit() {
    this.initSub = this.apiService.getCountries().subscribe(data => this.countries = data)
  }
 
}
