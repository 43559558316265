import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManufacturersComponent } from './pages/master-data/manufacturers.component';
import { AgrochemicalsComponent } from './pages/agrochemicals/agrochemicals.component';
import { ChemicalCategoriesComponent } from './pages/master-data/chemical-categories.component';
import { CountriesComponent } from './pages/master-data/countries.component';
import { ActiveIngredientsComponent } from './pages/master-data/active-ingredients.component';
import { ChemistsComponent } from './pages/master-data/chemists.component';
import { DataEntrySingle } from './pages/data-entry/data-entry-single.component';
import { SearchHistoryComponent } from './pages/searches/search-history.component';
import { SearchAdvancedComponent } from './pages/searches/search.component';
import { UserSettingsComponent } from './user-settings.component';
import { FormulationsComponent } from './pages/master-data/formulations.component';
import { HomeComponent } from './home.component';
import { DataEntryBulkComponent } from './pages/data-entry/data-entry-bulk.component';
import { BulkFromFolderComponent } from './pages/data-entry/bulk-from-folder.component';
import { RequestListComponent } from './pages/requests/request-list.component';
import { RequestSearchComponent } from './pages/requests/request-search.component';
import { RequestDetailComponent } from './pages/requests/request-detail.component';
import { UpsertAgrochemicalComponent } from './pages/agrochemicals/upsert-agrochemical.component';
import { UpsertManufacturerComponent } from './pages/master-data/upsert-manufacturer.component';
import { UpsertChemicalCategoryComponent } from './pages/master-data/upsert-chemical-category.component';
import { UpsertActiveIngredientComponent } from './pages/master-data/upsert-active-ingredient.component';
import { UpsertChemistComponent } from './pages/master-data/upsert-chemist.component';
import { YaraProductsComponent } from './pages/yara-products/yara-products.component';
import { UpsertYaraProductComponent } from "./pages/yara-products/upsert-yara-product.component";
import { ComponentDemoComponent } from './components/component-demo.component';
import { TankmixDetailComponent } from './pages/searches/tankmix-detail.component';


const routes: Routes = [
  { path: '', component: HomeComponent },

  { path: 'settings', component: UserSettingsComponent },
  { path: 'manufacturers', component: ManufacturersComponent },
  { path: 'formulations', component: FormulationsComponent },
  { path: 'agrochemicals', component: AgrochemicalsComponent },
  { path: 'agrochemical/create', component: UpsertAgrochemicalComponent },
  { path: 'agrochemical/edit/:chemicalId', component: UpsertAgrochemicalComponent },
  
  { path: 'manufacturer/create', component: UpsertManufacturerComponent },
  { path: 'manufacturer/edit/:manufacturerId', component: UpsertManufacturerComponent },
  
  { path: 'chemical-categories', component: ChemicalCategoriesComponent },
  { path: 'chemical-category/create', component: UpsertChemicalCategoryComponent },
  { path: 'chemical-category/edit/:chemicalCategoryId', component: UpsertChemicalCategoryComponent },
 
  { path: 'active-ingredients', component: ActiveIngredientsComponent },
  { path: 'active-ingredient/create', component: UpsertActiveIngredientComponent },
  { path: 'active-ingredient/edit/:activeIngredientId', component: UpsertActiveIngredientComponent },
 
  { path: 'chemists', component: ChemistsComponent },
  { path: 'chemist/create', component: UpsertChemistComponent },
  { path: 'chemist/edit/:chemistId', component: UpsertChemistComponent },
 
  { path: 'yara-products', component: YaraProductsComponent },
  { path: 'yara-product/create', component: UpsertYaraProductComponent },
  { path: 'yara-product/edit/:yaraProductId', component: UpsertYaraProductComponent },
 


  { path: 'countries', component: CountriesComponent },
 { path: 'newtest', component: DataEntrySingle },
  { path: 'bulk-file', component: DataEntryBulkComponent },
  { path: 'bulk-from-folder', component: BulkFromFolderComponent },
 
  { path: 'search/history', component: SearchHistoryComponent },

  { path: 'search/begin', component: SearchAdvancedComponent },
  { path: 'search/:historyItemIndex', component: SearchAdvancedComponent },
  { path: 'view-report/:tankmixId', component: TankmixDetailComponent },
  { path: 'request/list', component: RequestListComponent },
  { path: 'request/search', component: RequestSearchComponent },
  { path: 'request/detail/:requestId', component: RequestDetailComponent },
  { path: 'component-demo', component: ComponentDemoComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
