<h1>Search History</h1>
<div *ngIf="!previousSearches">
  <p>No previous searches have been recorded for you</p>
</div>

<div *ngIf="previousSearches">
  <table class='table-yara'>
    <thead>
      <tr>
        <th>Last Used</th>
        <th>Number Of Results Type</th>
        <th>Type Of Search</th>
        
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let previousSearch of previousSearches; let i=index">
        <td>{{previousSearch.at}}</td>
        <td>{{previousSearch.results}}</td>
        <td>
          <span *ngIf="previousSearch.advanced">Advanced</span>
          <span *ngIf="!previousSearch.advanced">Basic</span>
        </td>
        <td>{{previousSearch.criteria|json}}</td>
        <td>
          <fa-icon title='search' icon="search" size="1x" (click)='searchAgain(i)'></fa-icon>
        </td>
      </tr>
    </tbody>
  </table>
  <span *ngIf="!previousSearches.length" class="btn btn-success btn-sm mb-2" (click)='clearPreviousSearches()'>
    Clear Previous Searches
  </span>
</div>