import { Injectable } from '@angular/core';
import { LocalStorageService } from './localstorage.service';
import { LocationService } from './location.service';
import { GeoJsCountry } from '../models/geioJsCountry';
import { tap } from 'rxjs/operators';
import { StaffPreferences } from '../models/StaffPreferences';
import { BehaviorSubject, Observable, forkJoin, of, timer, interval, Subscription } from 'rxjs';
import { isThisHour } from 'date-fns';
import { NameId } from '../models/name-id';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {
  
  preferences$: BehaviorSubject<StaffPreferences> = new BehaviorSubject(null);
  detectedCountry: GeoJsCountry;
  preferences: StaffPreferences;
  needsReloadInternal = false;
  reloadTimerSub: Subscription;
  unitSetChoices:NameId[]=[{ id:"Metric", name:"Metric"},{id:"Imperial",name:"Imperial}"}];
  countryCode$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  languageCode$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  termsAndConditions$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  unitsChoice$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  seedUnitsChoice$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  chemist$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private storage: LocalStorageService, private location: LocationService) {
    this.termsAndConditions = this.storage.retrieve("terms") || false;
    this.unitsChoice = this.storage.retrieve("unitsChoice") ||"L/Ha";
    this.seedUnitsChoice = this.storage.retrieve("seedUnitsChoice") ||"L/ton";
    this.languageCode = this.storage.retrieve("languageCode") || 'en';
    this.chemist = this.storage.retrieve("chemist");

    var countryCode = this.storage.retrieve('countryCode');
    if (countryCode == null) {
      this.location.getCountry().subscribe(detectedLocation => {
        console.log("Country is",detectedLocation);
        this.countryCode = detectedLocation.country
      });
    }
    else {
console.log("Country from storage is ",countryCode);
      this.countryCode = countryCode;
    }
  }
 
  get needsReload(): boolean {
    var val = this.needsReloadInternal;
    this.needsReloadInternal = false;
    return val;
  }
  set needsReload(value: boolean) {
    this.needsReloadInternal = value;
    this.reloadTimerSub = timer(800)
    .subscribe(x => {
      navigator.serviceWorker.getRegistrations()
      .then(
         registrations=> { 
          for(let registration of registrations) { 
            registration.unregister() ;
          } } ) 
         location.replace('/'+this.languageCode$.value+'/index.html');
     // location.reload()
        });
      
        
  }

  set termsAndConditions(terms: boolean) {
    if (terms != this.termsAndConditions$.value) {
      this.storage.store('terms', terms);
      this.termsAndConditions$.next(terms);
    }
  }
  set unitsChoice( unitsChoiceId:string) {
    if (unitsChoiceId != this.unitsChoice$.value) {
      this.storage.store('unitsChoice', unitsChoiceId);
      this.unitsChoice$.next(unitsChoiceId);
    }
  }

  set seedUnitsChoice( seedUnitsChoiceId:string) {
    if (seedUnitsChoiceId != this.seedUnitsChoice$.value) {
      this.storage.store('seedUnitsChoice', seedUnitsChoiceId);
      this.seedUnitsChoice$.next(seedUnitsChoiceId);
    }
  }

  set countryCode(code: string) {
    if (code != this.countryCode$.value) {
      this.storage.store('countryCode', code);
      this.countryCode$.next(code);
    }
  }
  set chemist(chemistName: string) {
    if (chemistName != this.chemist$.value) {
      this.storage.store('chemist', chemistName);
      this.chemist$.next(chemistName);
    }
  }


  set languageCode(code: string) {
    if (code != this.languageCode$.value) {

      document.cookie = "lang=" + code + "; expires=31 Dec 2100 23:59:59 GMT path=/";
      this.storage.store('languageCode', code);
      this.languageCode$.next(code);

    }
  }
  forceToAmerica() {
   if (this.languageCode$.value!='en' || this.countryCode$.value!='us'){
    this.needsReload=true;
    this.languageCode="en";
    this.countryCode="us";
   }
  }
  convertWaterUnits(inp:number):number{
    switch(this.unitsChoice$.value)
    {
      case "L/ha":return inp;
      case "US gal/ac":return inp*0.106906781;//0.264172/2.47105
      case "US pt/ac":return inp*0.855255863;//2.11338/2.47105
    }
    return inp;
  }
  convertApplicationUnits(inp:number):number{
    switch(this.unitsChoice$.value)
    {
      case "L/ha":return inp;
      case "US gal/ac":return inp*0.106906781;//   0.264172/2.47105
      case "US pt/ac":return inp*0.855255863; //   2.11338/2.47105
    }
    return inp;
  }
  convertSeedUnits(inp:number):number{
    switch(this.seedUnitsChoice$.value)
    {
      case "L/ton":return inp *1000;//metric ton  1/0.001
      case "US gal/US ton":return inp*239.6530921;// 0.264172/0.00110231
      case "US pt/US ton":return inp*1935.7531; //     2.11338/0.00110231
    }
    return inp;
  }
  convertPowderUnits(inp:number):string{
    switch(this.unitsChoice$.value)
    {
    
      case "US gal/ac":
      case "US pt/ac":return  (inp*0.892171344).toFixed(2)+  " US lb/ac";//2.2046/2.47105
      default:
        return  (inp).toFixed(2)+ " kg/ha";
    }
    
  }

}
