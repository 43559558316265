import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'app-toasts',
  template: `
  <ngb-toast *ngFor="let toast of toastService.toasts" [class]="toast.classname" [autohide]="true" [delay]="toast.delay || 15000" (hide)="toastService.remove(toast)">
  <ng-template [ngIf]="hasTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.templateRef"></ng-template>
  </ng-template>

  <ng-template #text>
    <h3>{{toast.header}}</h3>
    {{ toast.body }}
  </ng-template>
</ngb-toast>
  `,
  styles: [`
  :host {
    position: fixed;
    top: 3rem;
    right: 0;
    margin: 0.5em;
    z-index: 1200;
   
  }
  
  h3 {color:white; font-size:1.2rem;}
  `],
  host: { '[class.ngb-toasts]': 'false' }
})
export class ToastsComponent implements OnInit {

  constructor(public toastService: ToastService) { }

  hasTemplate(toast) {
    if (toast.templateRef == null) return false;
    return toast.templateRef instanceof TemplateRef;
  }
  ngOnInit(): void {
  }

}
