import { Component, OnInit } from '@angular/core';
import { BackendAPIService } from '../../services/backend-api.service';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { debounceTime, Subscription } from 'rxjs';
import { Agrochemical } from 'src/app/models/master-data/Agrochemical';
import { Router } from '@angular/router';
@Component({
  selector: 'app-agrochemicals',
  templateUrl: './agrochemicals.component.html',
  styles: ['']
})
export class AgrochemicalsComponent implements OnInit {
  agroChemicals: Agrochemical[] = [];
  filteredAgroChemicals: Agrochemical[] = [];
  initSub: Subscription;
  filterForm: FormGroup;
  filterSub: Subscription;

  details: any = {};
  constructor(private apiService: BackendAPIService,
    private fb: UntypedFormBuilder,
    private router: Router
  ) {
    this.filterForm = this.fb.group({
      nameSearchText: [''],
      activeSearchText: [''],
      manufacturerSearchText: [''],
    });
    this.filterSub = this.filterForm
      .valueChanges
      .pipe(debounceTime(300))
      .subscribe(values => {
        this.applyFilter(values);
      });
  }

  ngOnInit() {
    this.initSub = this.apiService.getAgrochemicals().subscribe(data => {
      this.agroChemicals = data;
      this.filteredAgroChemicals = data;
      console.log(data);
    });
  }
  add() {
    this.router.navigateByUrl('agrochemical/create');
  }
  edit(i: number) {
    this.router.navigateByUrl('agrochemical/edit/' + this.filteredAgroChemicals[i].chemicalId);
  }
  applyFilter(values: any) {
    values.nameSearchText = values.nameSearchText.toLowerCase().trim();
    values.activeSearchText = values.activeSearchText.toLowerCase().trim();

    this.filteredAgroChemicals = this.agroChemicals.filter(x =>
      (values.nameSearchText == '' || (x.chemicalName || '').toLowerCase().includes(values.nameSearchText))
      && (values.activeSearchText == '' || (x.actives || []).filter(x => x.toLowerCase().includes(values.activeSearchText)).length > 0)
      && (values.manufacturerSearchText == '' || (x.manufacturer.name || '').toLowerCase().includes(values.manufacturerSearchText))

    )
  }

  clearFilter() {
    this.filterForm.patchValue({
      nameSearchText: '',
      activeSearchText: '',
      manufacturerSearchText: '',
    })
  }
}
