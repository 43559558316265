import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'faultCategory'
})
export class FaultCategoryPipe implements PipeTransform {

  transform(value: number): string {
    switch (value)
    {
      case 0: return 'Warning'; break;
      case 1: return 'Error'; break;
      default: return 'Unknown';
    }

  }

}
