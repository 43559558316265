import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ChemicalCategory } from 'src/app/models/ChemicalCategory';
import { BackendAPIService } from 'src/app/services/backend-api.service';

@Component({
  selector: 'app-upsert-chemical-category',
  templateUrl: './upsert-chemical-category.component.html',
  styles: [
  ]
})
export class UpsertChemicalCategoryComponent implements OnInit {
  chemicalCategoryId: string;
  chemicalCategoryForm: FormGroup;
  loaded: boolean = false;
  get title(): string {
    if (!this.loaded) return '';
    if (this.chemicalCategoryId == null) return 'Create  Chemical Category';
    return 'Edit Chemical Category';
  }
  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private api: BackendAPIService) {
    this.chemicalCategoryId = this.route.snapshot.params["chemicalCategoryId"];
    this.chemicalCategoryForm = this.fb.group({
      name: ['', Validators.required],
      id: [null],
      deactivated: [null],
      active: [true, Validators.required],
    });

    if (this.chemicalCategoryId != null) {
      this.api.getChemicalCategory(this.chemicalCategoryId)
        .subscribe(data => {
          data.active = data.deactivated == null;
          this.chemicalCategoryForm.patchValue(data);
          this.loaded = true;
        })
    }

    else {
      this.loaded = true;
    }

  }

  ngOnInit(): void {
  }
  delete() {
    this.api.deleteChemicalCategory(this.chemicalCategoryId).subscribe(() => {
      this.router.navigateByUrl('/chemical-categories');
    });
  }
  cancel() {
    this.router.navigateByUrl('/chemical-categories');
  }
  save() {
    let data = this.chemicalCategoryForm.value;
    if (data.active == true && data.deactivated != null) { data.deactivated = null }
    else if (data.active == false && data.deactivated == null) { data.deactivated = new Date }
    this.api.upsertChemicalCategory(data).subscribe(() => {
      this.router.navigateByUrl('/chemical-categories');
    });
  }
}
