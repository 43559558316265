<nav
  id="mainNavigation"
  class="navbar fixed-top navbar-expand-lg navbar-light bg-light"
>
  <a class="navbar-brand" routerLink="/">
    <yara-logo size="0.5"></yara-logo>
    <h2>Tankmix &trade; Administration</h2>
  </a>
  <button
    class="navbar-toggler pull-right"
    type="button"
    (click)="isNavbarCollapsed = !isNavbarCollapsed"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div
    [collapse]="isNavbarCollapsed"
    class="collapse navbar-collapse"
    id="navbarSupportedContent"
  >
    <ul class="navbar-nav mr-auto">
      <li class="btn" routerLink="/">Home</li>

      <li class="btn-group" dropdown>
        <button
          dropdownToggle
          type="button"
          class="btn btn-default dropdown-toggle"
        >
          Tankmixes
        </button>
        <ul *dropdownMenu class="dropdown-menu" role="menu">
          <li class="heading">Recording Tests</li>
          <li>
            <a class="dropdown-item" routerLink="/newtest">Record Single</a>
          </li>
          <!--
          <li>
            <a class="dropdown-item" routerLink="/bulk-file">
              Upload Bulk</a>
          </li>
        -->

          <li class="heading">Search</li>

          <li>
            <a class="dropdown-item" routerLink="/search/begin"> Search</a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="/search/history"
              >Search History</a
            >
          </li>
        </ul>
      </li>
      <li class="btn-group" dropdown>
        <button
          dropdownToggle
          type="button"
          class="btn btn-default dropdown-toggle"
        >
          Requests
        </button>
        <ul *dropdownMenu class="dropdown-menu" role="menu">
          <li>
            <a class="dropdown-item" routerLink="/request/list">Outstanding</a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="/request/search">Search</a>
          </li>
        </ul>
      </li>
      <li class="btn-group" dropdown>
        <button
          dropdownToggle
          type="button"
          class="btn btn-default dropdown-toggle"
        >
          Agrochemicals
        </button>
        <ul *dropdownMenu class="dropdown-menu" role="menu">
          <li> <a class="dropdown-item" routerLink="/agrochemicals">Agrochemicals</a></li>

   
           </ul>
      </li>
      <li class="btn-group" dropdown>
        <button
          dropdownToggle
          type="button"
          class="btn btn-default dropdown-toggle"
        >
          Markets
        </button>
        <ul *dropdownMenu class="dropdown-menu" role="menu">
        
          <li> <a class="dropdown-item" routerLink="/yara-products">Yara Products</a></li>
        </ul>
      </li>
     
      <li class="btn-group" dropdown>
        <button
          dropdownToggle
          type="button"
          class="btn btn-default dropdown-toggle"
        >
          Master Data
        </button>
        <ul *dropdownMenu class="dropdown-menu" role="menu">
          <li>
            <a class="dropdown-item" routerLink="/manufacturers"
              >Manufacturers</a
            >
          </li>

          <li>
            <a class="dropdown-item" routerLink="/chemical-categories"
              >Chemical Categories</a
            >
          </li>
          <li>
            <a class="dropdown-item" routerLink="/active-ingredients"
              >Active Ingredients</a
            >
          </li>
          <li>
            <a class="dropdown-item" routerLink="/countries">Countries</a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="/chemists">Chemists</a>
          </li>
        </ul>
      </li>

      <li class="btn" routerLink="/settings">Settings</li>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <button
        *ngIf="!isAuthorized"
        class="btn btn-outline-success my-2 my-sm-0 mr-sm-2"
        (click)="login()"
      >
        Login
      </button>
      <span>{{ userName }}</span
      >&nbsp;
      <button
        *ngIf="isAuthorized"
        class="btn btn-outline-success my-2 my-sm-0 mr-sm-2"
        (click)="logout()"
      >
        Logout
      </button>
    </form>
  </div>
</nav>
