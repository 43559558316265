
<app-landing *ngIf="!loggedIn"></app-landing>

<div *ngIf="loggedIn" [ngClass]="{mobileDevice: device.isMobile}">
  <app-yara-navbar></app-yara-navbar>
   <!-- <gs-progress-bar ></gs-progress-bar> -->
    <div class="mainContent">
        <router-outlet></router-outlet>
    </div>
    <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</div>
 