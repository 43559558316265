import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { AuthenticatedResult, OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription } from 'rxjs';
import { BackendAPIService } from '../services/backend-api.service';
import { DeviceService } from '../services/device.service';
import { IdentityService } from '../services/identity.service';
import { MonitorService } from '../services/monitor.service';

@Component({
  selector: 'app-yara-navbar',
  templateUrl: './yara-navbar.component.html',
  styleUrls: ['./yara-navbar.component.scss']
})
export class YaraNavBarComponent implements OnInit, OnDestroy {
  isAuthorized: boolean;
  isNavbarCollapsed: boolean;
  isAuthorizedSubscription: Subscription;
  userDataSubscription: Subscription;
  isFetchingSubscription: Subscription;
  isFetchingData: boolean;
  userName: string;
  nameSub: Subscription;
  constructor(public device: DeviceService,
    public oidcSecurityService: OidcSecurityService,
    private router: Router, 
    public identity: IdentityService, 
    private monitorService: MonitorService, 
    private api: BackendAPIService) {
   

   
      this.isAuthorizedSubscription = this.oidcSecurityService.checkAuth().subscribe((x: any) => {
        this.isAuthorized = x;
      });
      this.nameSub = this.identity.username$.subscribe(name =>{ this.userName = name;
      });
      this.userDataSubscription = this.oidcSecurityService.userData$.subscribe((resp: any) => {

        if (resp != null) {
        //  this.userName = resp.userData.email;
          //  this.api.fetchPhoto(resp.userData.picture).subscribe();
        }
      });
   
    router.events.subscribe((event: Event) => {

      if (event instanceof NavigationStart) {
        this.isNavbarCollapsed = true;
      }

      if (event instanceof NavigationEnd) {
        this.isNavbarCollapsed = true;
      }

      if (event instanceof NavigationError) {
        console.log(event.error);
      }
    });

  }
  ngOnInit() {
    this.isNavbarCollapsed = true;
  }
  ngOnDestroy(): void {
    this.isAuthorizedSubscription.unsubscribe();
    this.userDataSubscription.unsubscribe();
  }
  login() {
    console.log('User has clicked login');
    this.oidcSecurityService.authorize();
  }
  logout() {
    console.log('User has clicked logout');
    this.oidcSecurityService.logoff();
  }

  search() {
    console.log('User wants to search');
  }

}
