import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { forkJoin, interval, Subscribable, Subscription, timer } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { NameId } from '../../models/name-id';
import { BackendAPIService } from '../../services/backend-api.service';
import { ProcessingDTO, UploadInfo, UploadService } from '../../services/upload.service';

@Component({
  selector: 'app-data-entry-bulk',
  templateUrl: './data-entry-bulk.component.html',
  styles: [`
  .progress {
    width:50%;
  } 
  table.faultTable th.Error {
    background-color:red; 
    color:black;
    }
    table.faultTable th.Warning {
      background-color:orange; 
      color:black;
      } 
      td{}
      `]
})

export class DataEntryBulkComponent implements OnInit, OnDestroy {
  allValid = false;
  //selectedFormat: NameId;
  uploadFormats: NameId[];
  formatsSubscription: Subscription;
  public files: Set<File> = new Set();
  public uploads: UploadInfo[] = [];
  @ViewChild('file', { static: false }) file;
  fileIndex = 0;
  maindata;
  canBeClosed = true;
  showCancelButton = false;
  uploading = false;
  uploadSuccessful = false;
  processing = false;
  processingSuccessful = false;
  disableClose = false;
  uploadForm: FormGroup;
  constructor(private uploadService: UploadService, public sanitizer: DomSanitizer, 
    private api: BackendAPIService, private fb:FormBuilder) {
      this.uploadForm=this.fb.group({
        selectedFormat:['',Validators.required]
      });
     }
  ngOnDestroy(): void {
    if (this.formatsSubscription != null) {
      this.formatsSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.uploadFormats = [{ name: 'Excel Spreadsheet Version 1.0', id: '1' }];

  }
  
  addFiles() {
    this.file.nativeElement.click();
  }
  onFilesAdded() {
    const files: { [key: string]: File } = this.file.nativeElement.files;
    for (const key in files) {
      if (!isNaN(parseInt(key, 10))) {
        this.files.add(files[key]);
      }
    }
    this.files.forEach(element => {
      this.uploads.push({
        file: element, formatId: this.uploadForm.value.selectedFormat.id, formatName: this.uploadForm.value.selectedFormat.name,
        status: 'Uploading', uploadProgress: null, fileTransferId: null,
        numberOfChecks: null,
        timerSubscription: null,
        subscription: null,
        processingData: null,
        summary: null
      });
    });

    this.performUpload();
  }
  private abandonUploads() {
    this.uploads.forEach(upload => {
      if (upload.subscription != null) {
        upload.subscription.unsubscribe();
        upload.subscription = null;
      }
    });
    this.uploads.length = 0;
    this.files.clear();
  }
  performUpload() {
    this.uploads.forEach(upload => {
      upload.numberOfChecks = 0;
      upload.subscription = this.uploadService.UploadSingle(upload)
        .pipe(
          tap((fileTransferId: number) => {
            upload.status = 'Uploaded';
            upload.fileTransferId = fileTransferId;
            upload.processingData = null;
            upload.timerSubscription = interval(2000)
              .pipe(switchMap(_ => this.uploadService.fileTransferStatus(upload.fileTransferId)))
              .subscribe((check: ProcessingDTO) => this.dealWithStatusUpdate(check, upload), error => this.dealWithError(error, upload));
          })
        ).subscribe();
    });
  }
  dealWithError(error: any, upload: UploadInfo): void {

    upload.status = 'Server Error';
    upload.summary = 'Unknown';
    upload.processingData = error;
    upload.timerSubscription.unsubscribe();
  }
  dealWithStatusUpdate(check: ProcessingDTO, upload: UploadInfo) {
    upload.status = check.statusText;
    upload.summary = check.summary;
    upload.processingData = check;
    upload.numberOfChecks = upload.numberOfChecks + 1;
    if (upload.numberOfChecks >= 10 ||
      (check.statusText !== 'Queued' && check.statusText !== 'Processing'
        && check.statusText !== 'Uploaded')) { upload.timerSubscription.unsubscribe(); }
  }

  startAgain() {
    this.abandonUploads();
    this.uploadForm.patchValue({selectedFormat:null});
    this.uploading = false;

  }
  resubmit() {
    this.performUpload();
  }
  
}
