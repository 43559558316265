<h1>Search Requests</h1>
<div class='form-yara' [formGroup]="searchCriteriaForm">
	<div class="form-group">
		<label for="">Status</label>
		<app-nameid-select id="status" [items]="requestStatusChoices" formControlName="status"></app-nameid-select>
		
	</div>
	<div class="form-group">
		<label for="">From</label>
		<input type="text" id="fromDate" bsDatepicker  formControlName="fromDate">
	</div>
	<div class="form-group">
		<label for="">Until</label>
		<input type="text" id="untilDate" bsDatepicker  formControlName="untilDate">
	</div>
	<div class="form-group">
		<label for="">Requestor</label>
		<input type="text" id="requestor" formControlName="requestor">
	</div>
	<div class="form-group">
		<label for="">Ingredient</label>
		<input type="text" id="ingredient" formControlName="ingredient">
	</div>
	<div class="form-group">
		<label for="">Kind</label>
		<input type="text" id="kind" formControlName="kind">
	</div>


</div>
<ng-container *ngIf="results">

<h2>Search Results</h2>
<p *ngIf="!results.length">There are no outstanding requests</p>
<table class="table-yara" *ngIf=" results.length">
	<thead>
		<tr>
			<th>RequestId</th>
			<th>Created</th>
			<th>Requestor</th>
			<th>Kind</th>
			<th>Status</th>
			<th>Ingredients</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let result of results;let i=index" (click)="view(i)">
			<td>{{result.requestDetailId}}</td>
			<td>{{result.created}}</td>
			<td>{{result.requestor}}</td>
			<td>{{result.kind}}</td>
			<td>{{result.status}}</td>
			<td>

				<span *ngFor="let ingredent of result.ingredients">{{ingredent.chemical}}</span>
			</td>
		</tr>
	</tbody>
</table>
</ng-container>