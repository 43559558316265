import { Component, OnInit } from '@angular/core';
import { BackendAPIService } from '../../services/backend-api.service';
import { UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
@Component({
  selector: 'app-chemists',
  templateUrl: './chemists.component.html',
  styles: ['']
})
export class ChemistsComponent implements OnInit {
  chemists: any[];
  initSub:Subscription;
  constructor(private apiService: BackendAPIService,
    private fb: UntypedFormBuilder,
    private router:Router
    ) { }

  details: any = {};
  creating = false;
 
  ngOnInit() {
    this.initSub= this.apiService.getChemists().subscribe(data=>this.chemists=data);
  }
  add() {
   this.router.navigateByUrl('/chemist/create');
  }
  edit(i: number) {
    this.router.navigateByUrl('/chemist/edit/'+this.chemists[i].userId);
  }
  
}
