import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  private _accessToken: string=undefined;
  private _idToken:  string=undefined;
  cypressPrefix: string = 'cypress_assist_';
  constructor() { }
  loggedIn$:BehaviorSubject<boolean>=new BehaviorSubject(false);
  public get accessToken() {
    if (this._accessToken == undefined)
      this._accessToken = window.localStorage.getItem(this.cypressPrefix + 'access_token');
    return this._accessToken;
  }
  public get idToken() {
    if (this._idToken == undefined)
      this._idToken = window.localStorage.getItem(this.cypressPrefix + 'id_token');
    return this._idToken;
  }
  public set accessToken(value: string | undefined) {
    window.localStorage.setItem(this.cypressPrefix + 'access_token',value);
    this._accessToken = value;
  }
  public set idToken(value: string | undefined) {
   
    window.localStorage.setItem(this.cypressPrefix + 'id_token',value);
 
    this._idToken = value;
    this.loggedIn$.next(value==null);
  }

}
