import { Component, Input } from "@angular/core";

@Component({
  selector: "app-result-icon",
  template: `
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="60"
      height="60"
      viewBox="0 0 48 48"
      xml:space="preserve"
    >
      <g *ngIf="!value" transform="scale(0.45),translate(-260,-260)">
        <path
          style="clip-path:url(#SVGID_00000131345447323357638470000013185959609362772149_);fill:#FF4438;"
          d="M334.3,342.5
				c-1.7,0-3.4-0.7-4.7-2l-65.7-65.6c-2.6-2.6-2.6-6.9,0-9.5s6.9-2.6,9.5,0l65.7,65.6c2.6,2.6,2.6,6.9,0,9.5
				C337.7,341.9,336,342.5,334.3,342.5z"
        />
        <path
          style="fill:#FF4438;"
          d="M268.6,342.5
				c-1.7,0-3.4-0.7-4.7-2c-2.6-2.6-2.6-6.9,0-9.5l65.7-65.6c2.6-2.6,6.9-2.6,9.5,0s2.6,6.9,0,9.5l-65.7,65.6
				C272,341.9,270.3,342.5,268.6,342.5z"
        />
      </g>

      <g *ngIf="value" transform="scale(0.45),translate(-250,-260)">
        <path
          style="fill:#507A07;"
          d="M283.8,338
			c-1.9,0-3.7-0.7-5-2.1L250.1,307c-2.7-2.8-2.7-7.2,0-10c2.8-2.7,7.2-2.7,9.9,0l23.7,23.9l56.5-56.9c2.7-2.8,7.2-2.8,9.9,0
			c2.8,2.7,2.8,7.2,0,9.9l-61.5,62C287.5,337.3,285.7,338,283.8,338z"
        />
      </g>
    </svg>
  `,
})
export class ResultIconComponent {
  @Input()
  value: boolean = false;
  @Input()
  scale: number = 1.0;
}
