<h1>UI Component Demonstration</h1>

<div class="form-yara" [formGroup]="demoForm">
  <div class="form-group">
	<label for=""></label><app-nameid-typeahead>

	</app-nameid-typeahead>   </div>
  <div class="form-group"><label for="">Choose Airport(with typeahead)</label>
<app-nameid-typeahead [items]="alternateChoices" id="typeaheadControl" formControlName="typeaheadControl"></app-nameid-typeahead>
</div>
  <div class="form-group"><label for="">Choose Airport(with dropdown)</label>
<app-nameid-select   [items]="alternateChoices" id="dropdownControl" formControlName="dropdownControl"></app-nameid-select>
</div>
  <div class="form-group"><label for=""></label></div>
</div>
{{demoForm.value|json}}