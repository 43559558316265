import { environment } from "./environment";

export class APIPortResolver {
  static resolve(): string {
    var proto = window.location.protocol;
    var host = window.location.hostname;
    if (environment.apiPort == 443 || environment.apiPort == 80) {
      return proto + '//' + host + '/api/';
    } else {
      return proto + '//' + host + ':' + environment.apiPort + '/api/';
    }
  }
}
