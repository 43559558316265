import { Injectable,SecurityContext  } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
@Injectable({
  providedIn: 'root'
})
export class MonitorService {


  
  apiErrors: string[] = [];
  private numberOfLiveRequests: number = 0;
  constructor(private sanitizer:DomSanitizer){}
  liveRequestsCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  isFetchingData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  addError(errorMessage: string) {
    this.apiErrors.push(errorMessage);
  }
  getAllErrors(): string[] {
    return this.apiErrors;
  }
 
  private emitConnectionStatus() {

    this.isFetchingData.next(this.numberOfLiveRequests > 0);
  }
  startRequest() {
    this.numberOfLiveRequests++;
    this.liveRequestsCount.next(this.numberOfLiveRequests);
    if (this.numberOfLiveRequests==1)  this.isFetchingData.next(true);

  }
  finishRequest() {
    this.numberOfLiveRequests--;
    this.liveRequestsCount.next(this.numberOfLiveRequests);
    if (this.numberOfLiveRequests==0)
    this.isFetchingData.next(false);
   
  }
}
