import { Component, OnInit } from '@angular/core';
import { BackendAPIService } from '../../services/backend-api.service';
import { UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
@Component({
  selector: 'app-chemical-categories',
  templateUrl: './chemical-categories.component.html',
  styles: ['']
})
export class ChemicalCategoriesComponent implements OnInit {
  chemicalCategories: any[];
  initSub: Subscription;
  constructor(private apiService: BackendAPIService,
    private fb: UntypedFormBuilder,
    private router:Router) { }
  showDetails = false;
  details: any = {};
  creating = false;
 
 
  ngOnInit() {
    this.initSub=this.apiService.getChemicalCategories().subscribe(data=>this.chemicalCategories=data);
  }
  add() {
    this.router.navigateByUrl('/chemical-category/create');
  }
  edit(i: number) {
    this.router.navigateByUrl('/chemical-category/edit/'+this.chemicalCategories[i].id);
  }

}
