<h1>Active Ingredients</h1>
<app-add-button
  id="addActiveIngredient"
  
  (click)="add()"
></app-add-button>

<div *ngIf="activeIngredients">
  <div *ngIf="!activeIngredients">
    <p>No active ingredients have been loaded</p>
  </div>

  <div class="filter" [formGroup]="filterForm">
    <label for="">Name</label>
    <input type="text" formControlName="nameSearchText" />
  </div>
  <table class="table-yara">
    <thead>
      <tr>
        <th>Ingredient Name</th>
        <th>Ingredient Code</th>
        <th>Oral d 50</th>
        <th>Deactivated</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="result-row"
        *ngFor="
          let activeIngredient of filteredActiveIngredients;
          let i = index
        "
      >
        <td>{{ activeIngredient.activeIngredientName }}</td>
        <td> {{ activeIngredient.activeIngredientId}}</td>
        <td>{{ activeIngredient.oralLethalDose50}}</td>
        <td class="deactivated">{{activeIngredient.deactivated | date:"dd-MMM-yyyy"}}</td>
        
        <td>
          <app-edit-button (click)="edit(i)"></app-edit-button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
