import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { NameId } from 'src/app/models/name-id';
import { BackendAPIService } from 'src/app/services/backend-api.service';

@Component({
  selector: 'app-upsert-chemist',
  templateUrl: './upsert-chemist.component.html',
  styles: [
  ]
})
export class UpsertChemistComponent implements OnInit {
  chemistId: string;
  chemistForm: FormGroup;
  initSub: Subscription;
  chemistChoices: NameId[];
  loaded: boolean = false;
  get title(): string {
    if (this.chemistId == null) return 'Create Chemist';
    return 'Edit Chemist';
  }
  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private api: BackendAPIService) {
    this.chemistId = this.route.snapshot.params["chemistId"];
    this.chemistForm = this.fb.group({
      name: ['', Validators.required],
      userId: ['', Validators.required],
      email: ['', Validators.required],
      responsibleTo: [''],
    });
    if (this.chemistId) {
      this.initSub = forkJoin([this.api.getChemistChoices(), this.api.getChemist(this.chemistId)])
        .subscribe(([chemists, chemist]) => {
          this.chemistChoices = chemists;
          console.log('Recieved chemistdata', chemist)
          this.chemistForm.patchValue(chemist);
          this.loaded = true;
        }
        );
    } else {
      this.initSub = forkJoin([this.api.getChemistChoices()])
        .subscribe(([chemists]) => {
          this.chemistChoices = chemists;
          this.loaded = true;
        });

    }

  }

  ngOnInit(): void {

  }
  delete() {
    this.api.deleteChemist(this.chemistId).subscribe(() => {
      this.router.navigateByUrl('/chemists');
    });
  }
  cancel() {
    this.router.navigateByUrl('/chemists');
  }
  save() {

    this.api.upsertChemist(this.chemistForm.value).subscribe(() => {

      this.router.navigateByUrl('chemists');
    });
  }
}
