<h2>Find Previous Tankmixes</h2>

<div class="multiple-forms">
  <div class="form-yara" [formGroup]="tankmixesForm">
    <h3>Our Tankmixes</h3>

    <div class="form-group">
      <label for=""></label>
    </div>

    <div class="form-group">
      <label for="">Tankmix Test Code</label>
      <input type="text" placeholder="optional" formControlName="tankmixId" />
    </div>
    <div class="form-group">
      <label for="">From</label>
      <input bsDatepicker formControlName="fromDate" />
    </div>

    <div class="form-group">
      <label for="">Until</label>
      <input bsDatepicker formControlName="untilDate" />
    </div>
    <button type="button" class="btn btn-success btn-sm" (click)="performSearch()">
      Search
    </button>
  </div>
  <div>
    <h3>Our Formulations</h3>
    <div class="form-yara" [formGroup]="findFormulationForm">
      <div class="form-group">
        <label for="">Generic Name</label>
        <input type="text" placeholder="optional" formControlName="searchText" />
      </div>
      <div *ngFor="let product of foundYaraProducts">
        <input type="checkbox" name="" id=""><span>{{product.id}} - {{product.name}}</span>
      </div>
    </div>
  </div>
  <div>
    <h3>Other Manufacturers Products</h3>
    <div class="form-yara" [formGroup]="findAgrochemicalForm">
      <div class="form-group">
        <label for="">Product Name Search</label>
        <input type="text" placeholder="optional" formControlName="productSearchText" />
      </div>
      <div class="form-group">
        <label for="">Manufacturer Search</label>
        <input type="text" placeholder="optional" formControlName="manufacturerSearchText" />
      </div>
      <div class="form-group">
        <label for="">Active Ingredient</label>
        <app-string-typeahead placeholder="optional" [items]="activeIngredients" formControlName="activeIngredientSearchText"></app-string-typeahead>
   
      </div>
      <div *ngFor="let product of foundNonYaraProducts">
        <input type="checkbox" name="" id=""><span>{{product.id}} - {{product.name}}</span>
      </div>
    </div>
  </div>

</div>

<div class="fullSearchResults" *ngIf="results.length">
  <table class="table-yara">
    <thead>
      <tr>
        <th i18n="@@Date Tested">TankmixId</th>
        <th i18n="@@Date Tested">Date Tested</th>
         <th class="smallColumn" i18n="@@Water Volume">Water Volume</th>
         <th i18n="@@Products">Products</th>
         <th class="smallColumn" i18n="@@Result">Result</th>
         <th class="smallColumn" i18n="@@Download Report">Download Report</th>
     
      </tr>
    </thead>
    <tbody>
      <tr class="tankmixResultRow" *ngFor="let tankmix of results">
        <td>{{tankmix.tankmixId}}</td>
        <td>{{ tankmix.dateOfTest | date }}</td>
        
        <td>{{  tankmix.waterRate}}</td>
        
        <td class="ingredientTableCell">
          <table class="ingredientTable">
            <thead>
              <tr>
                <th class="numberColumn">Order</th>
                <th class="numberColumn">Form</th>
                <th class="numberColumn">Specified Rate </th>
                <th class="numberColumn">100ml Rate</th>
                <th class="ingredientname">Name</th>
                <th class="actives">Actives</th>
                <th class="manufacturerColumn">Manufacturer</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let ingredient of tankmix.ingredients">
                <td>{{ ingredient.orderOfAddition }}</td>
                <td>{{ingredient.form}}</td>
                <td><span >{{ ingredient.rate | number:'1.2-2'}}</span>  </td>
                <td><span >{{ ingredient.rate/(tankmix.waterRate*10)*1000 | number:'1.3-3'}}</span>  </td>
               
                <td class="ingredientName">
                  <span *ngIf="!ingredient.products">{{
                    ingredient.name
                    }}</span>
                  <div *ngIf="ingredient.products">
                    <span *ngFor="let product of ingredient.products">{{ product }},
                    </span>
                  </div>
                </td>
                <td class="actives">
                  <span *ngFor="let active of ingredient.activeIngredients">{{ active }},
                  </span>
                </td>
                <td>{{ ingredient.manufacturer }}</td>
              </tr>
            </tbody>
          </table>
          <span *ngIf="tankmix.observations">{{ tankmix.observations }}</span>
          <span class="phrase">{{ tankmix.phrase }}</span>
        </td>
        <td>
          <app-result-icon [value]="tankmix.result!='NC'"></app-result-icon>
        </td>
        <td class="clickable" routerLink="/view-report/{{ tankmix.tankmixId }}" 
        title="View report">
        <app-pdf-icon></app-pdf-icon>
      </td>
      </tr>
    </tbody>
  </table>
  <div promptWhenVisible (visible)="addMoreSearchResults()"></div>
</div>