import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, tap, forkJoin } from 'rxjs';
import { RequestDetail } from 'src/app/models/RequestDetail';
import { BackendAPIService } from 'src/app/services/backend-api.service';

@Component({
  selector: 'app-request-list',
  templateUrl: './request-list.component.html',
  styles: [
  ]
})
export class RequestListComponent implements OnInit, OnDestroy {
  initSub: Subscription;
  results: RequestDetail[];
  constructor(private api: BackendAPIService, private router: Router) {
    this.initSub = forkJoin([this.api.getOutstandingRequests()])
      .pipe(tap(([results]) => {
        this.results = results;
      }))
      .subscribe();
  }
  ngOnDestroy(): void {
    this.initSub.unsubscribe();
  }

  ngOnInit(): void {
  }
  view(index: number) {
    this.router.navigateByUrl('/request/detail/' + this.results[index].requestDetailId);
  }

}
