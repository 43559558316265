export class PushNotificationModel {
	endpoint: string;
	key: string;
	authSecret: string;

	constructor(key: string, endpoint: string, authSecret: string) {
		this.key = key;
		this.endpoint = endpoint;
		this.authSecret = authSecret;
	}
}

export class PushNotificationModel2 {
	endpoint: string;
	key: string;
	authSecret: string;

	constructor(key: ArrayBuffer, endpoint: string, authSecret: ArrayBuffer) {
			
		this.key = this.convertToString(key) ;
		this.endpoint = endpoint;
		this.authSecret =this.convertToString(authSecret) ;
	}
	private convertToString(buffer:ArrayBuffer):string
	{
		var binary = '';
		var bytes = new Uint8Array( buffer );
		var len = bytes.byteLength;
		for (var i = 0; i < len; i++) {
			binary += String.fromCharCode( bytes[ i ] );
		}
		return window.btoa( binary );
	}
	
}