<h1>{{title}}</h1>

<div >
   
    <div class="form-yara" [formGroup]="manufacturerForm">
      <div class="form-group">
        <label for="">Name</label><input type="text" id="name" formControlName="name" />
      </div>
      <div class="form-group">
        <label for="">Address</label
        ><input type="text" id="address"  formControlName="address" />
      </div>
      <div class="form-group">
        <label for="">Telephone</label
        ><input type="text" id="telephone"  formControlName="telephone" />
      </div>
      <div class="form-group">
        <label for="">Contact</label
        ><input type="text" id="contact"  formControlName="contact" />
      </div>
      <div class="form-group">
        <label for="">In Use</label
        ><input type="checkbox" id="inUse"  formControlName="inUse" />
      </div>
      <div class="form-group">
        <label for="">Manufacturer</label
        >
        {{manufacturerForm.value.manufacturerCode}}
        
      </div>
      <div class="form-group">
        <label for="">Deactivated</label
        ><input type="text" bsDatepicker  id="deactivated"  formControlName="deactivated" />
      </div>
    </div>

    <app-cancel-button id="cancel" (click)="cancel()"></app-cancel-button>
    <app-save-button [hidden]="!manufacturerForm.valid"  id="save" (click)="save()"></app-save-button>

     
  </div>