import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'tickCross' })
export class TickCrossPipe implements PipeTransform {
    transform(value: boolean): string {
        if (value) {
            return 'check';
        }
        return 'times';
    }
}
