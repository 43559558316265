<h1>{{ title }}</h1>
<div>
  <div class="form-yara" [formGroup]="chemicalCategoryForm">
    <div class="form-group">
      <label for="">Name</label
      ><input type="text" id="name" formControlName="name" />
    </div>

    <div class="form-group">
      <label for="">Active</label>
      <input type="checkbox" id="active" formControlName="active" />{{
        chemicalCategoryForm.value.deactivated | date: "dd-MMM-yyyy"
      }}
    </div>

    <app-cancel-button id="cancel" (click)="cancel()"></app-cancel-button>
    <app-save-button
      [hidden]="!chemicalCategoryForm.valid"
      id="save"
      (click)="save()"
    ></app-save-button>
  </div>
</div>
