
<h1>{{title}}</h1>

<div >
   
    <div class="form-yara" [formGroup]="yaraProductForm">
		
		<div class="form-group">
			<label for="">Yara Regional ProductId</label>
			
			<input type="text" id="yaraProductId" formControlName="yaraProductId"  maxlength="6" *ngIf="yaraProductId == null"/>
			<label *ngIf="yaraProductId != null" for="" class="fw-bold">{{ yaraProductId }}</label>
		  </div>
		  <div class="form-group">
			<label for="">Product Name</label><input type="text" id="yaraProductName" formControlName="yaraProductName" />
		  </div>
		
		  <div class="form-group">
			<label for="">Chemical Id (F no)</label><input type="text" id="chemicalId" formControlName="chemicalId" maxlength="5" />
		  </div>
		  <div class="form-group">
			<label for="">DH Code</label><input type="text" id="dhcode" formControlName="dhcode" />
		  </div>
		  <div class="form-group">
			<label for="">Form</label>
		    
			<app-nameid-select id="form" [items]="forms" formControlName="form"></app-nameid-select>
		  </div>
		  <div class="form-group">
			<label for="">Country Id</label>
			<app-nameid-typeahead type="text" id="country" formControlName="country" [items]="countries" [inputValue]="countryName"
                              showBorder="false"></app-nameid-typeahead>
			
		  </div>
		  <div class="form-group">
			<label for="">Visble</label><input type="checkbox" id="visible" formControlName="visible" />
		  </div>
		  <div class="form-group">
			<label for="">Is Yara Vita</label><input type="checkbox" id="isYaraVita" formControlName="isYaraVita" />
		  </div>

		  <div *ngIf="yaraProductId != null">
			<div class="form-group">
				<label for="">Created By</label>
				<!-- <input type="text" id="createdBy" readonly formControlName="createdBy" /> -->
				<label>{{ yaraProductForm.get('createdBy').value }}</label>
			  </div>
			  <div class="form-group">
				<label for="">Date Created</label>
				<!-- <input type="text" id="dateCreated" readonly formControlName="dateCreated" /> -->
				<label>{{ yaraProductForm.get('dateCreated').value  | date: 'dd/MM/yyyy'}}</label>
			  </div>
		  </div>


    </div>

    <app-cancel-button id="cancel" (click)="cancel()"></app-cancel-button>
    <app-save-button id="save" (click)="save()" [hidden]="!yaraProductForm.valid"></app-save-button>

     
  </div>
