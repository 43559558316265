import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { EffectiveIdentity } from "../models/EffectiveIdentity";
import { BackendAPIService } from "./backend-api.service";

@Injectable({
  providedIn: "root",
})
export class IdentityService {
  permissions:string[]=[];
  username:string='';
  public permissions$:BehaviorSubject<string[]>=new BehaviorSubject([]);
  public username$:BehaviorSubject<string>=new BehaviorSubject('');
  permissionsSubscription: Subscription;
  landingRole: string;

  constructor(private api: BackendAPIService) {
    this.permissionsSubscription = this.api
    .getEffectiveIdentity()
    .subscribe((effectiveIdentity:EffectiveIdentity) => {
        let permissions=effectiveIdentity.userRights;
        permissions=permissions.map(x=>x.toLowerCase());
        this.permissions = permissions;
        this.username$.next(effectiveIdentity.effectiveUsername);
        this.landingRole=permissions.find(x=>x.startsWith('landing'));
        this.permissions$.next(this.permissions) ;
      })
    ;
  }
 
  public hasPermission(permissionName: string) {
    return this.permissions.includes(permissionName);
  }
  public allPermissions(): string[] {
    return this.permissions;
  }
}
