<h1>Manufacturers</h1>
<app-add-button id="addManufacturer"  (click)="add()"></app-add-button>
<div *ngIf="manufacturers">
  <div *ngIf="!manufacturers">
    <p>No manufacturer data has been loaded</p>
  </div>
  

  <div *ngIf="manufacturers">
    <table class="table-yara">
      <thead>
        <tr>
          <th>Name</th>
          <th>Address</th>
          <th>Telephone</th>
          <th>Contact</th>
          <th>In Use</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="result-row"
          *ngFor="let manufacturer of manufacturers; let i = index"
        >
          <td>{{ manufacturer.name }}</td>
          <td>{{ manufacturer.address }}</td>
          <td>{{ manufacturer.telephone }}</td>
          <td>{{ manufacturer.contacty }}</td>
          <td>
            <fa-icon
              icon="{{ manufacturer.inUse | tickCross }}"
              size="1x"
            ></fa-icon>
          </td>
          <td>
            <app-edit-button  (click)="edit(i)"></app-edit-button>
               </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
