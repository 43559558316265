import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';

import { Observable, of, noop } from 'rxjs';
import { debounceTime, distinctUntilChanged, catchError, map } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
@Component({
	selector: 'app-string-typeahead',
	template: `
<input 
	[ngModel]="selectedItemLabel" 
	adaptivePosition="true"
    [typeahead]="items"
	(typeaheadOnSelect)="selectItem($event)"
	itemTemplate='kkk'
	[placeholder]="placeholder"
	class="app-string-typeahead-input" 
	[disabled]="isDisabled"
	>

<span *ngIf='showClear' class='button button-primary button-s' (click)='erase()'>Clear</span>
  `,
	styles: [`
        :host {
        display:inline-block;
        input {}
        &.hidden input { width: 0; border-style: none;}}
        table.table-sample{  input{  border-style:none;}}
		`],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef(() => StringTypeaheadComponent),
	}]
})
export class StringTypeaheadComponent
	implements OnInit, ControlValueAccessor {
	public selectedItemLabel: string;
	@Input() placeholder = '';
	@Input() showBorder = true;
	@Input() showClear = false;
	@Input() showId = true;
	public selectedItem: string;
	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;
	@Input() items: string[] = [];
	@Input() searchText: string;
	@Output() searchTextChange = new EventEmitter();
	isDisabled:boolean=false;
	constructor() { }
	ngOnInit() {

	}
	selectItem(ev) {
		console.log(ev.item)
		this.value = ev.item;
		this.selectedItemLabel = ev.item;
	}
	changeSearchText(ev) {
		
		this.selectedItem =  ev.target.value;
		this.searchText = ev.target.value;
		this.searchTextChange.emit(ev.target.value);
	}
	@Input()
	nameFormatter(x: string) {
		if (x == undefined) { return ''; }
	
		return x;
	}


	debounceSearchForItems = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			map(term => {
				if (term == '') { return []; }
				const uterms = term.toUpperCase().trim().split(' ');
				return this.items.filter(x => {
					for (const uterm of uterms) {
						if (!x.toUpperCase().includes(uterm)) {
							return false;
						}
					}
					return true;
				}
				).slice(0, 40);
			},
				catchError((e) => {
					return of([]);
				})

			)
		)

	get value(): any {
		return this.selectedItem;
	}

	set value(v: string) {
		if (v !== this.selectedItem) {
			this.selectedItem = v;
			this.selectedItemLabel = v;
			this.searchText = this.nameFormatter(v);
			this.onChangeCallback(v);
		}
	}
	erase() {
		this.value = null;// { id: '', name: '' };
	}
	onBlur() { this.onTouchedCallback(); }
	writeValue(value: string): void {

		if (value !== this.selectedItem) {
			this.selectedItem = value;
			this.selectedItemLabel = value;
			this.searchText = this.nameFormatter(value);
		}
	}
	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}
	registerOnTouched(fn: any) { this.onTouchedCallback = fn; }

	setDisabledState?(isDisabled: boolean): void {
		this.isDisabled=isDisabled;
	}
}
