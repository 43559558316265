import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  storagePrefix: string;
  constructor() {
    this.storagePrefix = 'yara_staff_tankmix_';

  }
  remove(key: string) {
    if (window.localStorage == null) { return; }
    window.localStorage.removeItem(this.storagePrefix + key);
  }
  removeAll(key: string) {
    if (window.localStorage == null) { return; }
    window.localStorage.clear();
  }

  retrieve(key: string) {
    if (window.localStorage == null) { return; }
    const retrievedString = window.localStorage.getItem(this.storagePrefix + key);
    if (retrievedString == null) { return null; }
    const asObject = JSON.parse(retrievedString);
    const now = new Date();
    if (asObject.expiry < now) {
      this.remove(key);
      return null;
    }
    return asObject.payload;
  }

  store(key: string, myObject: any, givenExpiry: Date = null) {
        if (window.localStorage == null) { return; }
    if (myObject == null) { return; }
    let expiryToSet;
    if (givenExpiry == null) {
      expiryToSet = new Date(2099, 12, 31);
    } else {
      expiryToSet = givenExpiry;
    }
    const storageObject = {
      expiry: expiryToSet,
      payload: myObject
    };
    const asJson = JSON.stringify(storageObject);
    window.localStorage.setItem(this.storagePrefix + key, asJson);
  }

}
