import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GeoJsCountry } from '../models/geioJsCountry';
@Injectable({
  providedIn: 'root'
})

export class LocationService {

  constructor(private http: HttpClient) { }

  getCountry(): Observable<GeoJsCountry> {
    return this.http.get<GeoJsCountry>('https://get.geojs.io/v1/ip/country.json');
  }
}
