import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { BackendAPIService } from './backend-api.service';
import { Subscription, Observable, Subject, BehaviorSubject, forkJoin, merge } from 'rxjs';
import { NameId } from '../models/name-id';
import { tap, mergeAll } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SelectionDataService implements OnInit, OnDestroy {
  public agrochemicals$: BehaviorSubject<NameId[]> = new BehaviorSubject<NameId[]>([]);
  public countries$: BehaviorSubject<NameId[]> = new BehaviorSubject<NameId[]>([]);
  public chemists$: BehaviorSubject<NameId[]> = new BehaviorSubject<NameId[]>([]);
  public activeIngredients$: BehaviorSubject<NameId[]> = new BehaviorSubject<NameId[]>([]);
  public results$: BehaviorSubject<NameId[]> = new BehaviorSubject<NameId[]>([]);

  adminDataSubscription: Subscription;
  initSub: Subscription;
  constructor(private api: BackendAPIService) {
 

    this.refreshAllSelections();
  }

  ngOnInit(): void {
   
  }
  refreshAllSelections() {
    this.initSub = forkJoin([
      this.api.getAgrochemicalChoices(),
      this.api.getResultChoices(),
      this.api.getCountries(),
      this.api.getChemistChoices(),
      this.api.getActiveIngredientChoices()
    ]).subscribe(
      ([agrochemicals, results, countries, chemists, activeIngredients]) => {
        this.agrochemicals$.next(agrochemicals);
        this.results$.next(results);
        this.countries$.next(countries);
        this.chemists$.next(chemists);
        this.activeIngredients$.next(activeIngredients);
       }

    );
  }
  ngOnDestroy(): void {
    if (this.adminDataSubscription != null) this.adminDataSubscription.unsubscribe();
    if (this.initSub != null) this.initSub.unsubscribe();
  }
}
