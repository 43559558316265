import { Component, OnDestroy, OnInit } from "@angular/core";
import { ScanData } from "../../models/scanData";
import { BackendAPIService } from "../../services/backend-api.service";
import { Subject, Subscription, timer } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
@Component({
  selector: "app-bulk-from-folder",
  templateUrl: "./bulk-from-folder.component.html",
  styleUrls: ["./bulk-from-folder.component.scss"],
})
export class BulkFromFolderComponent implements OnInit, OnDestroy {
  scanData: ScanData = { status: "dormant" };
  timerSubscription: Subscription;

  private stopTimer = new Subject();
  constructor(private api: BackendAPIService) {}
  ngOnDestroy(): void {
    this.stopTimer.next;
    if (this.timerSubscription != null) {
      this.timerSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {}
  scanStop() {
    this.api.scanStop();
    this.stopTimer.next;
    this.scanData.status = "Cancelled";
  }
  scanStart() {
    this.scanData.status = "Contacting Server";
    this.timerSubscription ==
      this.api
        .scanStart()
        .pipe(
          (x) => timer(1, 3000),
          switchMap((x) => this.api.scanStatus()),
          takeUntil(this.stopTimer)
        )
        .subscribe((x) => {
          this.scanData = x;

          if (x.status == "Completed" || x.status == "Cancelled") {
          }
        });
  }
}
