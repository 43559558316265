import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NameId } from '../models/name-id';

@Component({
  selector: 'app-component-demo',
  templateUrl: './component-demo.component.html',
  styles: [
  ]
})
export class ComponentDemoComponent implements OnInit {
  choices: NameId[] = [
    {id:'1',name:'Choice 1'},
    {id:'2',name:'Choice 2'},
    {id:'3',name:'Choice 3'},
    {id:'4',name:'Choice 4'},
  ];
  alternateChoices: NameId[] = [
    {id:'LGW',name:'Gatwick'},
    {id:'LBA',name:'Leeds Bradford'},
    {id:'MAN',name:'Manchester'},
    {id:'ALC',name:'Alicante'},
  ];

  demoForm: FormGroup;
  constructor(private fb: FormBuilder) {
    this.demoForm = this.fb.group(
      {
        typeaheadControl: [null],
        dropdownControl: [null],

      }
    );
  }

  ngOnInit(): void {
  }

}
