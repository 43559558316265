<h1>{{ title}}</h1>

<div>
  <div class="form-yara" [formGroup]="chemistForm">
    <div class="form-group">
      <label for="">Name</label
      ><input type="text" id="name" formControlName="name" />
    </div>
    <div class="form-group">
      <label for="">Username</label
      ><input type="text" id="userId" formControlName="userId" />
    </div>
    <div class="form-group">
      <label for="">Email Address</label
      ><input type="text" id="email" formControlName="email" />
    </div>
    <div class="form-group">
      <label for="">Responsible To</label
      >
      <app-nameid-value-select [items]="chemistChoices"   id="responsibleTo" formControlName="responsibleTo" >

      </app-nameid-value-select>

    </div>
  </div>

  <app-cancel-button is="cancel" (click)="cancel()"></app-cancel-button>
  <app-save-button id="save" (click)="save()"></app-save-button>
  <app-delete-button id="delete" (click)="delete()"></app-delete-button>

</div>
