import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NameId, NameIdSelected } from "../models/name-id";
import { Observable, map, of } from "rxjs";
import { environment } from "src/environments/environment";
import { SelectionData } from "../models/selectionData";
import { ScanData } from "../models/scanData";
import { EffectiveIdentity } from "../models/EffectiveIdentity";
import { Agrochemical } from "../models/master-data/Agrochemical";
import { ActiveIngredient } from "../models/master-data/ActiveIngredient";
import { Chemist } from "../models/master-data/Chemist";
import { Manufacturer } from "../models/master-data/Manufacturer";
import { Formulation } from "../models/master-data/Formulation";
import { RequestDetail } from "../models/RequestDetail";
import { RequestComment } from "../models/RequestComment";
import { RequestSearchCriteria } from "../models/RequestSearchCriteria";
import { YaraProduct, YaraProductUpsert } from "../models/master-data/YaraProduct";
import { ChemicalCategory } from "../models/ChemicalCategory";
import { TankmixChangeDetailsDTO, TankmixSearchResultDto } from "../models/TankmixDetails";
import { FastStaffSearchCriteria } from "../models/FastSearchCriteria";
import { APIPortResolver } from "src/environments/APIPortResolver";
@Injectable()
export class BackendAPIService {

  apiPrefix: string;
  samplingPrefix: string;
  constructor(private http: HttpClient) {
    this.apiPrefix = APIPortResolver.resolve() + "staff/";
  }
  addCommentToRequest(requestCommentDto:RequestComment) {
    return this.http.post<void>(this.apiPrefix + "request-comment",requestCommentDto);
  }
  getUsersCountries(): Observable<NameId[]> {
    return this.http.get<NameId[]>(this.apiPrefix + "users-countries");
  }
  getYaraProducts(criteria:any) {
    return this.http.post<YaraProduct[]>(this.apiPrefix + "yara-products",criteria);
  }
  deleteYaraProduct(yaraProductId: string) {
    return this.http.delete<void>(this.apiPrefix + "yara-product/"+yaraProductId);

  }
  upsertYaraProduct(update: any) {
    console.log('api call');
    return this.http.post<boolean>(this.apiPrefix + "yara-product",update);
  }

  getYaraRegionalProduct(yaraProductId: string) {
    return this.http.get<YaraProductUpsert>(this.apiPrefix + "yara-product/" + yaraProductId);
  }

  deleteChemist(chemistId: string) {
    return this.http.delete<void>(this.apiPrefix + "chemist/"+chemistId);

  }
  getOutcome(tankmixId: string, languageCode:string): Observable<TankmixSearchResultDto> {
    console.log(this.apiPrefix + 'outcome/' + tankmixId +'/'+ languageCode);
    return this.http.get<TankmixSearchResultDto>(this.apiPrefix + 'outcome/' + tankmixId +'/'+ languageCode);
  }

  getActiveIngredient(activeIngredientId:string):Observable<ActiveIngredient> {
    return this.http.get<ActiveIngredient>(this.apiPrefix + "active-ingredient/"+activeIngredientId);
  }
  upsertActiveIngredient(update: any) {
    return this.http.post<void>(this.apiPrefix + "active-ingredient",update);
  }
  deleteActiveIngredient(activeIngredientId: string) {
    return this.http.delete<void>(this.apiPrefix + "active-ingredient/"+activeIngredientId);
  }
  upsertChemicalCategory(update: any) {
    return this.http.post<void>(this.apiPrefix + "chemical-category",update);
  }
  deleteChemicalCategory(chemicalCategoryId: string) {
    return this.http.delete<void>(this.apiPrefix + "chemical-category/"+chemicalCategoryId);

  }
  getManufacturer(manufacturerId: string):Observable<Manufacturer>  {
    return this.http.get<Manufacturer>(this.apiPrefix + "manufacturer/"+manufacturerId);
  }
  getChemicalCategory(chemicalCategoryId:string):Observable<ChemicalCategory> {
    return this.http.get<ChemicalCategory>(this.apiPrefix + "chemical-category/"+chemicalCategoryId);
  }
  getChemist(chemistId:string):Observable<Chemist> {
    return this.http.get<Chemist>(this.apiPrefix + "chemist/"+chemistId);
  }
  upsertChemist(update: any) {
    return this.http.post<void>(this.apiPrefix + "chemist",update);
  }
  upsertManufacturer(update: any) {
    return this.http.post<void>(this.apiPrefix + "manufacturer",update);
  }
  deleteManufacturer(manufacturerId: string) {
    return this.http.delete<void>(this.apiPrefix + "manufacturer/"+manufacturerId);
  }
  deleteAgrochemical(chemicalId: any) {
    return this.http.delete<void>(this.apiPrefix + "agrochemical/"+chemicalId);
 
  }
  upsertAgrochemical(update: Agrochemical) {
    return this.http.post<void>(this.apiPrefix + "agrochemical",update);
  }
  getRequestStatusChoices():Observable<NameId[]> {
    return this.http.get<NameId[]>(this.apiPrefix + "request-status-choices");
  }
  getRequestDetail(requestId: string): Observable<RequestDetail> {
    return this.http.get<RequestDetail>(this.apiPrefix + "request/detail/"+requestId);
  }
  getOutstandingRequests(): Observable<RequestDetail[]> {
    return this.http.get<RequestDetail[]>(this.apiPrefix + "request/list");
  }
  searchRequests(criteria:Partial<RequestSearchCriteria>): Observable<RequestDetail[]> {
    return this.http.post<RequestDetail[]>(this.apiPrefix + "request/search",criteria);
  }
  
  searchYaraProductNames(searchText:string):Observable<NameIdSelected[]>{
    if (searchText==null || searchText.trim()=='')
    return of([]);
    return this.http.get<NameIdSelected[]>(this.apiPrefix + "search-yara-product-names/"+searchText);
  }
  searchNonYaraProductNames(criteria):Observable<NameIdSelected[]>{
  console.log('Searching',criteria)
   return this.http.post<NameIdSelected[]>(`${this.apiPrefix}search-non-yara-product-names`,criteria);
  }

  fastSearch(criteria:FastStaffSearchCriteria):Observable<TankmixSearchResultDto[]>{
    
    return this.http.post<TankmixSearchResultDto[]>(`${this.apiPrefix}fast-search`, criteria);
  }

  getEffectiveIdentity(): Observable<EffectiveIdentity> {
    return this.http.get<EffectiveIdentity>(this.apiPrefix + "effective-identity");
  }
  
  getAdminData(): Observable<SelectionData> {
    return this.http.get<SelectionData>(this.apiPrefix + "admin-data");
  }
  getManufacturers(): Observable<Manufacturer[]> {
    return this.http.get<Manufacturer[]>(this.apiPrefix + "manufacturers");
  }

  getManufacturersNameIdArray(): Observable<NameId[]> {
    return this.http.get<Manufacturer[]>(this.apiPrefix + "manufacturers").pipe(
      map((activeIngredients: Manufacturer[]) => {
        return activeIngredients.map((manufacturer: Manufacturer) => {
          return {
            id: manufacturer.manufacturerCode,
            name: manufacturer.name
          };
        });
      })
    );
  }

  getAgrochemicals(): Observable<Agrochemical[]> {
    return this.http.get<Agrochemical[]>(this.apiPrefix + "agrochemicals");
  }
  getFormulations(): Observable<Formulation[]> {
    return this.http.get<Formulation[]>(this.apiPrefix + "yara-formulations");
  }
  getChemicalCategories(): Observable<NameId[]> {
    return this.http.get<NameId[]>(this.apiPrefix + "chemical-categories");
  }
  getActiveIngredients(): Observable<ActiveIngredient[]> {
    return this.http.get<ActiveIngredient[]>(this.apiPrefix + "active-ingredients");
  }

  getActiveIngredientsName() : Observable<NameId[]> {
    return this.http.get<ActiveIngredient[]>(this.apiPrefix + "active-ingredients").pipe(
      map((activeIngredients: ActiveIngredient[]) => {
        return activeIngredients.map((ingredient: ActiveIngredient) => {
          return {
            id: ingredient.activeIngredientId,
            name: ingredient.activeIngredientName
          };
        });
      })
    );
  }

  getActiveIngredientNamesArray(): Observable<string[]> {
    return this.http.get<ActiveIngredient[]>(this.apiPrefix + "active-ingredients").pipe(
      map((activeIngredients: ActiveIngredient[]) => {
        return activeIngredients.map(ingredient => ingredient.activeIngredientName);
      })
    );
  }

  getCountries(): Observable<NameId[]> {
    return this.http.get<NameId[]>(this.apiPrefix + "countries");
  }

  getChemists(): Observable<Chemist[]> {
    return this.http.get<Chemist[]>(this.apiPrefix + "chemists");
  }
  getAgrochemical(agrochemicalId:string): Observable<Agrochemical> {
    return this.http.get<Agrochemical>(this.apiPrefix + "agrochemical/"+agrochemicalId);
  }
  getAgrochemicalChoices(): Observable<NameId[]> {
    return this.http.get<NameId[]>(this.apiPrefix + "agrochemical-choices");
  }
  getFormulationChoices(): Observable<NameId[]> {
    return this.http.get<NameId[]>(this.apiPrefix + "formulation-choices");
  }
  getResultChoices(): Observable<NameId[]>  {
    return this.http.get<NameId[]>(this.apiPrefix + "result-choices");
  }
   getChemistChoices(): Observable<NameId[]>  {
    return this.http.get<NameId[]>(this.apiPrefix + "chemist-choices");
  }
  getActiveIngredientChoices(): Observable<NameId[]>  {
    return this.http.get<NameId[]>(this.apiPrefix + "active-ingredient-choices");
  }
  addNewTankmix(tankmix:TankmixChangeDetailsDTO): Observable<TankmixSearchResultDto>
  {
     return this.http.post<TankmixSearchResultDto>(this.apiPrefix+"add-tankmix",tankmix);
  }
  changeTankmix(tankmix:TankmixChangeDetailsDTO): Observable<TankmixSearchResultDto>
  {
     return this.http.post<TankmixSearchResultDto>(this.apiPrefix+"edit-tankmix",tankmix);
  }
  search() {
    const url = this.apiPrefix + "values";
    
    return this.http.get<NameId[]>(url).subscribe((x: any) => {
      
    });
  }
  scanStart(): Observable<ScanData> {
    
    return this.http.post<ScanData>(this.apiPrefix + "scan-start", {});
  }
  scanStop(): Observable<ScanData> {
    return this.http.post<ScanData>(this.apiPrefix + "scan-stop", {});
  }
  scanStatus(): Observable<ScanData> {
    return this.http.get<ScanData>(this.apiPrefix + "scan-status");
  }
}
