import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpEventType,
  HttpResponse,
  HttpHeaders,
  HttpParams,
  HttpEvent,
} from '@angular/common/http';
import { Subject, Observable, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { filter, map, tap } from 'rxjs/operators';
import { NameId } from '../models/name-id';
import { APIPortResolver } from 'src/environments/APIPortResolver';

export class UploadingResult { }

export class ProcessingDTO {

  status: number;
  statusText: string;
  summary: string;
  faults: any[];
  jobId: string;
}

export interface ProgressStatus {
  status: ProgressStatusEnum;
  percentage?: number;
}

export enum ProgressStatusEnum {
  START,
  COMPLETE,
  IN_PROGRESS,
  ERROR,
}

export class UploadInfo {
  file: File;
  formatId: string;
  formatName: string;
  uploadProgress?: Observable<number>;
  status: string;
  summary: string;
  subscription: Subscription;
  numberOfChecks: number;
  url?: string;
  processingData?: ProcessingDTO;
  timerSubscription: Subscription;
  fileTransferId: number;
}
@Injectable()
export class UploadService {
 
  public sessionEstablished = false;

  apiPrefix: string;
  constructor(private http: HttpClient) {
    this.apiPrefix = APIPortResolver.resolve()+ 'staff/';
  }

  public UploadSingle(fileStuff: UploadInfo): Observable<any> {
    const formData: FormData = new FormData();
    formData.append(fileStuff.file.name, fileStuff.file, fileStuff.file.name);
    formData.append('formatId', fileStuff.formatId);
    formData.append('formatName', fileStuff.formatName);
    const progress = new Subject<number>();
    fileStuff.uploadProgress = progress.asObservable();
    return this.http.post(this.apiPrefix + 'file-transfer', formData, { observe: 'events', reportProgress: true })
      .pipe(tap((event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          progress.next(Math.round((100 * event.loaded) / event.total));
        }
      }), filter((event: HttpEvent<any>) => event.type === HttpEventType.Response)
        , map((event: HttpResponse<any>) => event.body)
      );

  }
  public process(fileStuffArray: Array<UploadInfo>): Observable<ProcessingDTO>[] {
    const observables = [];
    fileStuffArray.forEach((fileStuff) => { observables.push(this.processSingle(fileStuff)); });
    return observables;
  }
  public processSingle(fileStuff: UploadInfo): Observable<ProcessingDTO> {
    fileStuff.status = 'Processing';
    console.log(`Now Processing ${fileStuff.formatName} File ${fileStuff.file.name}`);
    const req = this.http
      .post<ProcessingDTO>(this.apiPrefix + 'process-file',
        { filename: fileStuff.file.name, formatId: fileStuff.formatId, formatName: fileStuff.formatName, })
      .pipe(
        tap((processingDTO) => {
          fileStuff.status = 'Processed';
          fileStuff.processingData = processingDTO;
        })
      );
    return req;
  }
 
  fileTransferFormats(): Observable<NameId[]> {
    return this.http.get<NameId[]>(this.apiPrefix + 'file-transfer-formats');
  }

  fileTransferStatus(fileTransferId: number): Observable<ProcessingDTO> {

    return this.http.get<ProcessingDTO>(`${this.apiPrefix}file-transfer-status/${fileTransferId}`);

  }
}
