import { Component, OnInit } from '@angular/core';
import { BackendAPIService } from 'src/app/services/backend-api.service';
import { UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Formulation } from 'src/app/models/master-data/Formulation';
@Component({
  selector: 'app-formulations',
  templateUrl: './formulations.component.html',
  styles: []
})
export class FormulationsComponent implements OnInit {
  yaraFormulations: Formulation[];
  initSub: Subscription;
  constructor(private apiService: BackendAPIService,private fb: UntypedFormBuilder) { }
  showDetails = false;
  details: any = {};
  creating = false;
  yaraProductForm = this.fb.group({
    formulationCode: [''],
    genericName: [''],
    created: [''],
    current: [''],
    compat: [''],
    lastUpdate: [''],
    perHectare: [''],
    powderLiquid: [''],
    dHCode: [''],
    products: [''],
    visible: [''],
    specification: [''],
    unitCode: [''],
    dHCodeDescription: [''],
   
  });
  ngOnInit() {
    this.initSub= this.apiService.getFormulations().subscribe(data=>this.yaraFormulations=data);
  }
  add() {
    this.showDetails = true;
    this.creating = true;
  }
  edit(i: number) {
    this.showDetails = true;
    this.creating = false;
  }
  delete(i: number) { }
  cancel() { this.showDetails = false; }
  save() { this.showDetails = false; }
  saveAll() { }
}
