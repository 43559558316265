import { Injectable, Injector } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { TokenStorageService } from "../services/tokenStorage.service";
import { environment_auth } from "src/environments/environment_auth";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private cache: TokenStorageService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let requestToForward = req;
    if (this.cache !== undefined) {
      let token: any = undefined;
      if (!req.url.match(/staff.tankmix/)) {
        //do nothing
     } else
       if (req.url === environment_auth.userInfoEndpoint) {
        token = this.cache.accessToken;
      } else {
        token = this.cache.idToken;
      }
      if (token !== undefined) {
        requestToForward = req.clone({
          setHeaders: { Authorization: "Bearer " + token },
        });
      }
    } else {
      console.log("OidcSecurityService undefined: NO auth header!");
    }

    return next.handle(requestToForward);
  }
}
