import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription, tap } from 'rxjs';
import { NameId } from 'src/app/models/name-id';
import { RequestDetail } from 'src/app/models/RequestDetail';
import { BackendAPIService } from 'src/app/services/backend-api.service';

@Component({
  selector: 'app-request-detail',
  templateUrl: './request-detail.component.html'
})
export class RequestDetailComponent implements OnInit, OnDestroy {
  requestId: any;
  initSub: Subscription;
  details: RequestDetail;
  commentForm:FormGroup;
  statusChoices:NameId[]=[
    {id:'New',name:'New'},
    {id:'CancelledStaff',name:'Cancelled by Staff'},
    {id:'CancelledRequestor',name:'Cancelled by Requestor'},
    {id:'Completed',name:'Completed'},
    {id:'AwaitingProduct',name:'Awaiting Ingredients'},
    {id:'AwaitingClarification',name:'Awaiting Infomration From Requestor'},
    {id:'ScheduledSoon',name:'Scheduled Soon'},
    {id:'Processing',name:'Processing'},
    
  ];
  constructor(private route: ActivatedRoute,
    private router:Router,
    private api: BackendAPIService, private fb:FormBuilder) {
    this.requestId = this.route.snapshot.params["requestId"];
    this.loadDetails();
      this.commentForm=this.fb.group({
        newComment:['',Validators.required],
        status:[]
      })
  }
  loadDetails(){
    this.initSub = forkJoin([this.api.getRequestDetail(this.requestId)])
    .pipe(tap(([details]) => {
      this.details = details;
      this.commentForm.patchValue({status:{id:details.status,name:details.status}});
    }))
    .subscribe();
  }
  ngOnDestroy(): void {
    this.initSub.unsubscribe();
  }
  addComment(){
    this.commentForm.value.newComment;
    let req=   {
      requestDetailId:this.details.requestDetailId,
      comment:this.commentForm.value.newComment,
      status:this.commentForm.value.status.id
    };
    this.details=null;
    this.api.addCommentToRequest( req  ).subscribe(()=>{
       
       this.loadDetails();

    })
  }

  ngOnInit(): void {
  }

}
