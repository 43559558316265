<h1 [hidden]="!loaded">{{ title }}</h1>
{{title}}
<div class="form-yara" [formGroup]="agroChemicalForm">
<div class="row">
  <div class="col">

      <div class="form-group">
        <label for="">Manufacturer</label>
        <app-nameid-typeahead id="manufacturer" [items]="manufacturers"
                              formControlName="manufacturer"></app-nameid-typeahead>
        <app-add-button id="addManufacturer" (click)="addManufacturer()"></app-add-button>
      </div>
      <div class="form-group">
        <label for="">Chemical Category</label><app-nameid-select id="chemicalCategory" [items]="chemicalCategories"
                           formControlName="chemicalCategory"></app-nameid-select>
      </div>
      <div class="form-group">
        <label for="">Chemical Name</label><input type="text" id="chemicalName" formControlName="chemicalName" />
      </div>
      <div class="form-group">
        <label for="">Country</label>
        <app-nameid-typeahead type="text" id="country" formControlName="country" [items]="countries"
                              showBorder="false"></app-nameid-typeahead>
      </div>
<div class="form-group">
  <label for="">Ingredients</label>
  <div class="form-group">
   
    <div class="table-block">
      
    <table class="table-yara">
      <thead>
        <tr>
          <th>Ingredient</th>       
          <th></th>
        </tr>
      </thead>
      <tbody formArrayName="actives">
     
           <ng-container *ngFor="let ingredient of ingredients.controls; let i = index">
          <tr [formGroup]="ingredient">
            <td>
            
              <app-nameid-typeahead [items]="ingredientsArray" [formControl]="ingredients.at(i)" [inputValue]="ingredients.at(i).value">
              </app-nameid-typeahead>
            </td>
            <td *ngIf="ingredients.controls.length > 1">
              <fa-icon
              title="Delete"
              icon="trash-alt"
              size="1x"
              (click)="deleteIngredient(i)"
            ></fa-icon>
            </td>
          </tr>
        </ng-container> 
        
        <tr>
          <td>
            <app-add-button id="addIngredient" (click)="addIngredient()"></app-add-button>
          </td>
        </tr>
      </tbody>
      </table>
  </div>
  </div>
</div>


      <div class="form-group">
        <label for="">Form</label>
        <app-nameid-select id="form" [items]="forms" formControlName="form"></app-nameid-select>
      </div>
      <div class="form-group">
        <label for="">Standard Rate</label><input type="number" id="standardRate" formControlName="standardRate" />
      </div>
      <div class="form-group">
        <label for="">In Stock</label><input type="checkbox" id="isInStock" formControlName="isInStock" />
      </div>
      <div class="form-group">
        <label for="">Yara Product</label><input type="checkbox" id="isYaraProduct" formControlName="isYaraProduct" />
      </div>

      <div class="form-group">
        <label for="">Current</label><input type="checkbox" id="isCurrent" formControlName="isCurrent" />
      </div>

      <div class="form-group">
        <label for="">Visible</label><input type="checkbox" id="isVisible" formControlName="isVisible" />
      </div>





      <div class="form-group">
        <label for="">FormulaId</label><input type="text" id="formulaId" formControlName="formulaId" />
      </div>
      <div class="form-group">
        <label for="">Specification</label><input type="text" id="specification" formControlName="specification" />
      </div>


      <div class="form-group">
        <label for="">Per Hectare</label>
        <input type="text" id="perHectare" formControlName="perHectare" />
      </div>


      <div class="form-group">
        <label for="">Date Arrived</label>
        <input type="date" id="dateArrived" bsDatepicker formControlName="dateArrived" />
      </div>
      <div class="form-group">
        <label for="">Date Withdrawn</label>
        <input type="date" id="dateWithdrawn" bsDatepicker formControlName="dateWithdrawn" />
      </div>

      <div class="form-group">
        <label for="">Date Launched</label><input type="date" id="dateLaunched" bsDatepicker
               formControlName="dateLaunched" />
      </div>




      <div class="form-group">
        <label for="">Trademark</label><input type="text" id="trademark" formControlName="trademark" />
      </div>
      <div class="form-group">
        <label for="">Created By</label> {{ agroChemicalForm.value.createdBy }}
      </div>
      <div class="form-group">
        <label for="">Date Created</label>
        {{ agroChemicalForm.value.dateCreated | date: "dd-MMM-yyyy" }}
      </div>
      <div class="form-group">
        <label for="">Last Updated</label>
        {{ agroChemicalForm.value.dateUpdated | date: "dd-MMM-yyyy" }}
      </div>
      <div class="form-group">
        <label for="">Chemical Id</label>
        {{ agroChemicalForm.value.chemicalId }}

      </div>


     

  </div>

  <div class="col-md-3 col-12">
  

  </div>

</div>
</div>
<div>
  <app-cancel-button id="cancel" (click)="cancel()"></app-cancel-button>
   <app-save-button id="save" [hidden]="!agroChemicalForm.valid" (click)="save()"></app-save-button> 
 
</div>
