import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, UntypedFormArray, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { ActiveIngredient } from 'src/app/models/master-data/ActiveIngredient';
import { Agrochemical } from 'src/app/models/master-data/Agrochemical';
import { Manufacturer } from 'src/app/models/master-data/Manufacturer';
import { NameId } from 'src/app/models/name-id';
import { BackendAPIService } from 'src/app/services/backend-api.service';

@Component({
  selector: 'app-upsert-agrochemical',
  templateUrl: './upsert-agrochemical.component.html',
  styles: [
  ]
})
export class UpsertAgrochemicalComponent implements OnInit {

  agroChemicalForm: FormGroup;

  chemicalId: string;
  initSub: Subscription;
  loaded: boolean = false;
  forms: NameId[] = [
    { id: "P", name: "Powder" },
    { id: "L", name: "Liquid" },
  ];
  manufacturers: NameId[];
  countries: NameId[];
  chemicalCategories: NameId[];
  activeIngredients: NameId[] = [];
  ingredientsArray: string[] = [];


  get title(): string {
    if (this.chemicalId == null) return 'Create Agrochemical';
    return 'Edit Agrochemical';
  }
  constructor(
    
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private api: BackendAPIService

  ) {
    this.chemicalId = this.route.snapshot.params["chemicalId"];

    this.agroChemicalForm = this.fb.group({
      manufacturer: [null, Validators.required],
      chemicalCategory: [null, Validators.required],
      chemicalId: [null],
      chemicalName: [null, Validators.required],
      form: [null, Validators.required],
      country: [null, Validators.required],
      createdBy: [null],
      formulaId: [null],
      specification: [null],
      isInStock: [false, Validators.required],
      isYaraProduct: [false, Validators.required],
      isVisible: [false, Validators.required],
      isCurrent: [true, Validators.required],
      dateCreated: [null],
      dateArrived: [null],
      dateWithdrawn: [null],
      dateLaunched: [null],
      dateUpdated: [null],
      standardRate: [null],
      perHectare: [null],
      trademark: [null],
      actives: this.fb.array([
        [null, Validators.required] 
      ])
    },
    { validator: this.checkEntireForm });
    if (this.chemicalId == null) {
      this.initSub = forkJoin([
        this.api.getManufacturersNameIdArray(),
        this.api.getCountries(),
        this.api.getChemicalCategories(),

        this.api.getActiveIngredientNamesArray()
      ]).subscribe(([manufacturers, countries, chemicalCategories, ingredientsArray

      ]) => {
        this.manufacturers = manufacturers;
        this.countries = countries;
        this.chemicalCategories = chemicalCategories;
        this.ingredientsArray = ingredientsArray;
        this.loaded = true;
       // this.addIngredient();
        
      });
    } else {
      this.initSub = forkJoin([
        this.api.getManufacturersNameIdArray(),
        this.api.getCountries(),
        this.api.getChemicalCategories(),
        this.api.getAgrochemical(this.chemicalId),
        this.api.getActiveIngredientNamesArray()
      ]).subscribe(([manufacturers, countries, chemicalCategories, details,ingredientsArray

      ]) => {
        this.manufacturers = manufacturers;
        console.log(manufacturers);
        this.countries = countries;
        this.chemicalCategories = chemicalCategories;
        this.ingredientsArray = ingredientsArray;


        this.agroChemicalForm.patchValue(details);
       
        console.log(this.agroChemicalForm.get('manufacturer').value);
        this.populateActivesArray(details);
        this.loaded = true;
        console.log(details);
        
      });
   
    }
  }

  checkEntireForm(control: AbstractControl): ValidationErrors | null {
    const myActives = control.get("actives") as FormArray; 
    if (myActives.controls.length == 0) {
      return { numberOfMyActivesOK: false }; 
    }
    return null;
  }

  addIngredient() {
    const activesArray = this.agroChemicalForm.get('actives') as FormArray;
    activesArray.push(this.fb.control('', Validators.required));
  }

  deleteIngredient(i: number) {
      this.ingredients.removeAt(i);
  }




  get ingredients(): FormArray {
    return this.agroChemicalForm.get('actives') as FormArray;
  }
  ngOnInit(): void {
  }

  delete() {
    this.api.deleteAgrochemical(this.chemicalId).subscribe(() => {
      this.router.navigateByUrl('/agrochemicals');
    });
  }
  cancel() {
    this.router.navigateByUrl('/agrochemicals');
  }
  save() {
   

    console.log(this.agroChemicalForm.value);
  
    this.api.upsertAgrochemical(this.agroChemicalForm.value).subscribe(() => {
     this.router.navigateByUrl('/agrochemicals');
    });
  }
  saveAll() { }
  addManufacturer() {
    this.router.navigateByUrl('/manufacturer/create');
  }


 populateActivesArray(details: any): void {

  
    const actives = this.agroChemicalForm.get('actives') as FormArray;
   
    while (actives.length !== 0) {
      actives.removeAt(0);
    }

    if (details.actives && Array.isArray(details.actives) && details != undefined) {
      details.actives.forEach((active: string) => {
        actives.push(this.fb.control(active, Validators.required));
      });
    }
    //else {
     // actives.push(this.fb.control('', Validators.required));
    //}
   
}


  
}
