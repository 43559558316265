<div [hidden]="!loaded">
  <h1>{{ title }}</h1>

  <div class="form-yara" [formGroup]="activeIngredientForm">
    
    <div class="form-group">
      <label for="">Ingredient Name</label
      ><input
        type="text"
        id="activeIngredientName"
        formControlName="activeIngredientName"
      />
    </div>
    <div class="form-group">
      <label for="">Ingredient Code</label
      ><input readonly
        type="text"
        id="activeIngredientId"
        formControlName="activeIngredientId"
      />
    </div>
    <div class="form-group">
      <label for="">Oral Lethal Dose 50</label
      ><input value="0"
        type="text"
        id="oralLethalDose50"
        formControlName="oralLethalDose50"
      />
    </div>
   
    <div class="form-group">
      <label for="">Active</label>
      <input type="checkbox" id="active" formControlName="active" />{{
        activeIngredientForm.value.deactivated | date: "dd-MMM-yyyy"
      }}
    </div>
  </div>

  <app-cancel-button id="cancel" (click)="cancel()"></app-cancel-button>
  <app-save-button [hidden]="!activeIngredientForm.valid" id="save" (click)="save()"></app-save-button>
</div>
