import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BackendAPIService } from 'src/app/services/backend-api.service';

@Component({
  selector: 'app-upsert-active-ingredient',
  templateUrl: './upsert-active-ingredient.component.html',
  styles: [
  ]
})
export class UpsertActiveIngredientComponent implements OnInit {
  activeIngredientId: string;
  activeIngredientForm: FormGroup;
  loaded: boolean = false;
  initSub: Subscription;
  get title(): string {
    if (this.activeIngredientId == null) return 'Create Active Ingredient';
    return 'Edit Active Ingredient';
  }
  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private api: BackendAPIService) {
    this.activeIngredientId = this.route.snapshot.params["activeIngredientId"];
    this.activeIngredientForm = this.fb.group({

      activeIngredientId: [null],
      activeIngredientName: ['', Validators.required],
      oralLethalDose50: ['0', Validators.required],
      deactivated: [null],
      active: [true, Validators.required]
    });
    if (this.activeIngredientId == null) {
      this.loaded = true;
    }
    else {
      this.initSub = this.api.getActiveIngredient(this.activeIngredientId).subscribe(data => {
        data.active = data.deactivated == null;
        this.activeIngredientForm.patchValue(data);
        this.loaded = true;
      })
    }
  }

  ngOnInit(): void {
  }
  delete() {
    this.api.deleteActiveIngredient(this.activeIngredientId).subscribe(() => {
      this.router.navigateByUrl('/active-ingredients');
    });
  }
  cancel() {
    this.router.navigateByUrl('/active-ingredients');
  }
  save() {
    let data = this.activeIngredientForm.value;
    if (data.active == true && data.deactivated != null) { data.deactivated = null }
    else if (data.active == false && data.deactivated == null) {
      data.deactivated = new Date();

    }
    console.log('Active Ingredient', data);
    this.api.upsertActiveIngredient(data).subscribe(() => {
      this.router.navigateByUrl('/active-ingredients');
    });
  }
}
