import { Injectable } from '@angular/core';
import { AdvancedSearchCriteria } from '../models/advancedSearchCriteria';
import { SearchHistoryItem } from '../models/seachHistoryItem';
import { SearchCriteria } from '../models/searchCriteria';

@Injectable({
  providedIn: 'root'
})
export class SearchHistoryService {
  clear() {
    this.items = [];
    this.store();
  }
  storagePrefix: string = "tankmix";
  maxSearchesToRemember: number = 10;
  items: SearchHistoryItem[];
  constructor() {
    var itemsString = window.localStorage.getItem(this.storagePrefix + 'search-history');
    this.items = [];
    if (itemsString != null) {
      this.items = JSON.parse(itemsString);
    }
  }
  getRecentHistory(): SearchHistoryItem[] {

    return this.items;
  }
  addAdvancedSearchHistoryItem(criteria: AdvancedSearchCriteria, numResults?: number) {
    this.storeItem( criteria,true, numResults);
  }
  addSearchHistoryItem(criteria: SearchCriteria, numResults?: number) {
    this.storeItem(criteria, false, numResults);
  }
  private storeItem(criteria: SearchCriteria| AdvancedSearchCriteria,advanced:boolean, numResults?: number) {
    let historyItem: SearchHistoryItem = {
      at: new Date,
      advanced:advanced,
      criteria: criteria,
      results: numResults
    }
    this.items.push(historyItem);
    if (this.items.length > this.maxSearchesToRemember) {
      this.items.splice(0, 1);
    }
    this.store();
  };
  private store() {
    window.localStorage.setItem(this.storagePrefix + 'search-history',
      JSON.stringify(this.items));
  } 
   getPreviousSearch(index:number):SearchHistoryItem {
    return this.items[index];
  }
}
