import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticatedResult, EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { DeviceService } from './services/device.service';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from './services/tokenStorage.service';

import { NotificationsService } from './services/notifications.service';
import { SwPush } from '@angular/service-worker';
import { environment_auth } from 'src/environments/environment_auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Tankmix Administration';
  userDataSubscription: Subscription;
  authorizationSubscription: Subscription;
  checkAuthSubscription: Subscription;
  effectiveIdSubscription: Subscription;
  userData: any;
  loggedIn = false;
  isMobile: boolean;
  constructor(public device: DeviceService,
    public oidcSecurityService: OidcSecurityService,
    private eventService: PublicEventsService,
    private router: Router,
    public cache: TokenStorageService,

    private notificationsService: NotificationsService,
    readonly swPush: SwPush 
  ) {
   
  }

  ngOnInit() {

    this.checkAuthSubscription = this.oidcSecurityService.checkAuth()
      .subscribe(
        (loginResponse) => {
          console.debug('CheckAuth', loginResponse);
          if (loginResponse.isAuthenticated) {
            console.debug("Caching auth tokens")
            this.cache.idToken = loginResponse.idToken;
            this.cache.accessToken = loginResponse.accessToken;
            this.loggedIn = true;
            
            if (environment.serviceWorker) {
            this.notificationsService.subscribeToPush();
            //this.notificationsService.subscribeToPushNotifications();
          }
        }

        }
      );

    this.eventService
      .registerForEvents()
      .subscribe(
        (value) => {
          switch (value.type) {
            case EventTypes.CheckSessionReceived:
              break;
            case EventTypes.ConfigLoaded:
              break;
            case EventTypes.UserDataChanged:
              break;
            case EventTypes.NewAuthenticationResult:
              if (value.value.authorizationState == 'Authorized' || environment_auth.bypassLogin) {
                console.log('Authorization OK');
              }
              else {
                console.log('Authorization OK');
              }
              break;
            case EventTypes.TokenExpired:
              console.log('TokenExpired');
              break;
            case EventTypes.IdTokenExpired:
              console.log('IdTokenExpired');
              break;
          }
        }
      );

    if (environment_auth.bypassLogin && this.cache.idToken != null)
      this.loggedIn = true;

  }
  ngOnDestroy(): void {
    if (this.effectiveIdSubscription != null)
      this.effectiveIdSubscription.unsubscribe();
    if (this.checkAuthSubscription != null)
      this.checkAuthSubscription.unsubscribe();
  }
  login() {
    console.log('Calling this.oidcSecurityService.authorize');
    this.oidcSecurityService.authorize();
  }

}
