import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BackendAPIService } from 'src/app/services/backend-api.service';

@Component({
  selector: 'app-upsert-manufacturer',
  templateUrl: './upsert-manufacturer.component.html',
  styles: [
  ]
})
export class UpsertManufacturerComponent implements OnInit {
 manufacturerForm:FormGroup;
  manufacturerId: string;
  initsub:Subscription;
  loaded:boolean=false;
  get title(): string {
    if (this.manufacturerId == null) return 'Create Manufacturer';
    return 'Edit Manufacturer';
  }
  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private api: BackendAPIService) {
    this.manufacturerId = this.route.snapshot.params["manufacturerId"];
    this.manufacturerForm = this.fb.group({
      name: [null, Validators.required],
      address: [null],
      telephone: [null],
      contact: [null],
      inUse: [true],
      manufacturerCode: [null],
      deactivated: [null],
    });
    if (this.manufacturerId!=null)
    {
      this.api.getManufacturer(this.manufacturerId).subscribe(details=>{
        this.manufacturerForm.patchValue(details);
        this.loaded=true;
      });
    }else{
      this.loaded=true;
    }
  }

  ngOnInit(): void {
  }
  delete() { 
    this.api.deleteManufacturer(this.manufacturerId).subscribe(() => {
      this.router.navigateByUrl('/manufacturers');
    });
  }
  cancel() {
    this.router.navigateByUrl('/manufacturers');
  }
  save() {
    this.api.upsertManufacturer(this.manufacturerForm.value).subscribe(() => {
      this.router.navigateByUrl('/manufacturers');
    });
  }
}
