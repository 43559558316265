import { Component, OnInit } from '@angular/core';
import { BackendAPIService } from '../../services/backend-api.service';
import { Observable, Subscription, debounceTime } from 'rxjs';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
@Component({
  selector: 'app-active-ingredients',
  templateUrl: './active-ingredients.component.html',
  styles: ['']
})
export class ActiveIngredientsComponent implements OnInit {
  activeIngredients: any[];
  filteredActiveIngredients: any[];
  initSub: Subscription;
  filterForm: FormGroup;
  filterSub: Subscription;
  constructor(private apiService: BackendAPIService, 
    private fb: UntypedFormBuilder,
    private router:Router) {
    this.filterForm = this.fb.group({
      nameSearchText: [''],
    });
    this.filterSub = this.filterForm.valueChanges.pipe(debounceTime(500)).subscribe(values => {
      values.nameSearchText = values.nameSearchText.toLowerCase().trim();

      this.filteredActiveIngredients = this.activeIngredients.filter(x =>
        (values.nameSearchText == '' || (x.activeIngredientName || '').toLowerCase().includes(values.nameSearchText))
      )
    });
  }

  details: any = {};
 
 

  ngOnInit() {
    this.initSub =
      this.apiService.getActiveIngredients().subscribe(data => {
        this.activeIngredients = data;
        this.filteredActiveIngredients = data;
    
      }
    );
  }
  add() {
    this.router.navigateByUrl('active-ingredient/create');

  }
  edit(index: number) {
    this.router.navigateByUrl('/active-ingredient/edit/'+this.filteredActiveIngredients[index].activeIngredientId);
  }
 
}
