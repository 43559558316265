import { Component, OnInit } from "@angular/core";
import { NameId, NameIdSelected } from "../../models/name-id";
import { SelectionDataService } from "src/app/services/selection-data.service";
import {
  combineLatest,
  debounceTime,

  switchMap,
  tap,
} from "rxjs";
import { Form, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { add, addMonths } from "date-fns";
import { BackendAPIService } from "src/app/services/backend-api.service";
import { FastStaffSearchCriteria } from "src/app/models/FastSearchCriteria";
import { TankmixSearchResultDto } from "src/app/models/TankmixDetails";
@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styles: [""],
})
export class SearchAdvancedComponent implements OnInit {
  activeIngredients: string[];
  formulations: NameId[];
  pesticides: NameId[];
  tankmixesForm: UntypedFormGroup;
  findFormulationForm: UntypedFormGroup;
  findAgrochemicalForm: UntypedFormGroup;
  foundYaraProducts: NameIdSelected[] = [];
  foundNonYaraProducts: NameIdSelected[] = [];
  results: TankmixSearchResultDto[] = [];
  tankmixCriteria: any = {};
  constructor(
    private api: BackendAPIService,
    private selectionService: SelectionDataService,
    private fb: UntypedFormBuilder
  ) {
    this.selectionService.activeIngredients$.subscribe(
      (data) => (this.activeIngredients = data.map(x=>x.name))
    );
    this.selectionService.agrochemicals$.subscribe(
      (data) => (this.formulations = data)
    );
    var today = new Date();
    this.tankmixesForm = this.fb.group({
      tankmixId: [null],
      fromDate: [addMonths(today, -48)],
      untilDate: [today],
    });
    this.findFormulationForm = this.fb.group({
      searchText: [null],
    });
    this.findAgrochemicalForm = this.fb.group({
      productSearchText: [null],
      manufacturerSearchText: [null],
      activeIngredientSearchText: [null],
    });
  }

  ngOnInit() {
    combineLatest([
      this.findFormulationForm.valueChanges.pipe(
        debounceTime(300),
        switchMap((values) =>
          this.api.searchYaraProductNames(values.searchText)
        ),
        tap((results) => (this.foundYaraProducts = results))
      ),
      this.findAgrochemicalForm.valueChanges.pipe(
        debounceTime(300),
        switchMap((values) =>
          this.api.searchNonYaraProductNames({
            productSearchText: values.productSearchText,
            manufacturerSearchText: values.manufacturerSearchText,
            activeIngredientSearchText: values.activeIngredientSearchText,
          })
        ),
        tap((results) => (this.foundNonYaraProducts = results))
      ),
      this.tankmixesForm.valueChanges.pipe( debounceTime(300),
        tap((values) => (this.tankmixCriteria = values))
      ),
    ])
      .pipe(
        tap(([yaraProducts, nonYaraProducts, tankmixCriteria]) => {
          console.log("Yara prods are ", yaraProducts);
          console.log("Other prods are ", nonYaraProducts);
          console.log("Tm Criteria are ", tankmixCriteria);
          
        }),
        switchMap(([yaraProducts, nonYaraProducts, tankmixCriteria])=>this.api
        .fastSearch({
          tankmixId: tankmixCriteria.tankmixId,
          from: tankmixCriteria.from,
          until: tankmixCriteria.until,
          yaraChemicalIds: yaraProducts.map((x) => x.id),
          agroChemicalIds: nonYaraProducts.map((x) => x.id),
        })),
        tap(results=>this.results=results)
      )
      .subscribe();
      var today = new Date();
      this.tankmixesForm.patchValue({
        tankmixId: null,
      fromDate: addMonths(today, -48),
      untilDate: today,
      });
  }

  performSearch() {
    
    
  }
  addMoreSearchResults() {}
}
