import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Pipe({ name: 'widthSylePercent' })
export class WidthStylePercentPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(value: number): SafeStyle {
    if (value == null) { return '0%'; }
    const style = 'width:' + value.toString() + '%';
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }
}
