<h1>Record a tankmix</h1>
<div class="form-yara-widetext" [formGroup]="newTankmixForm">
  <div class="form-group wide" >
    <label for="">Country</label>
    <app-nameid-select   [items]="countryChoices"  id="country" formControlName="country" ></app-nameid-select>
  </div>
  <div class="form-group wide" >
    <label for="">Test Basis</label>
    <app-nameid-select   [items]="testBasisChoices"  id="testBasis" formControlName="testBasis" ></app-nameid-select>
  </div>
  <div class="form-group wide">
    <label for="">Date Tested</label
    ><input bsDatepicker id="dateOfTest" formControlName="dateOfTest" />
  </div>
  <div class="form-group wide">
    <label for="">Water Rate</label
    ><input
      type="number"
      id="waterRate"
      formControlName="waterRate"
      placeholder="l/ha please"
    />
  </div>
  <div *ngIf="!formValues.unusualTester" class="form-group wide">
    <label for="">Tester</label>
    <app-nameid-select
      [showBorder]="false"
      *ngIf="!formValues.unusualTester"
      id="chemist"
      [items]="chemistChoices"
      formControlName="chemist"
    ></app-nameid-select>
   
    <input
      *ngIf="formValues.unusualTester"
      type="text"
      name="oneOffTesterName"
      id="oneOffTesterName"
      class="form-control form-control-sm mr-2"
      placeholder=""
    />
  </div>
  <div class="form-group wide">
    <label for="">Result</label
    ><app-nameid-select 
      [items]="resultChoices"
      formControlName="result"
      [showBorder]="false"
    ></app-nameid-select>
  </div>
  <div class="form-group wide">
    <label for="">Observations</label
    ><textarea
      type="text"
      formControlName="observations"
      placeholder=""
    ></textarea>
  </div>
  <div class="form-group">
    <label for="">Ingredients</label>
    <div class="table-block">
    <table class="table-yara">
      <thead>
        <tr>
          <th>Rate</th>
          <th>Product</th>
        
          <th></th>
        </tr>
      </thead>
      <tbody formArrayName="ingredients">
        <ng-container
          *ngFor="let ingredientForm of ingredients.controls; let i = index">
          <tr [formGroup]="ingredientForm">
            <td><input type="number" min="0" class="rate" formControlName="rate" /></td>
          
            <td>
              <app-nameid-typeahead [items]="agrochemicals" formControlName="agrochemical">
              </app-nameid-typeahead>
            </td>
            <td>
              <fa-icon
                title="Delete"
                icon="trash-alt"
                size="1x"
                (click)="deleteProduct(i)"
              ></fa-icon>
              <fa-icon *ngIf="i>0" class="clickable"
                title="Move Up"
                icon="arrow-up"
                size="1x"
                (click)="moveProductUp(i)"
              ></fa-icon>
              <fa-icon class="clickable" *ngIf="i<ingredients.controls.length-1"
                title="Move Down"
                icon="arrow-down"
                size="1x"
                (click)="moveProductDown(i)"
              ></fa-icon>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    
    <app-add-button
      *ngIf="newTankmixForm"
      (click)="addProduct()"
    ></app-add-button>
  </div>
  </div>

</div>

<app-save-button
  *ngIf="newTankmixForm.valid"
  (click)="save()"
></app-save-button>