import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SearchHistoryItem } from 'src/app/models/seachHistoryItem';
import { SearchHistoryService } from 'src/app/services/search-history.service';
import { BackendAPIService } from '../../services/backend-api.service';
@Component({
  selector: 'app-search-history',
  templateUrl: './search-history.component.html',
  styles: ['']
})
export class SearchHistoryComponent implements OnInit {

  previousSearches: SearchHistoryItem[];
  constructor(private apiService: BackendAPIService,
    private searches: SearchHistoryService, private router: Router
  ) { }

  ngOnInit() {
    this.previousSearches = this.searches.getRecentHistory();
  }
  clearPreviousSearches() {
    this.searches.clear();
  }
  searchAgain(index: number) {
    var historyItem = this.previousSearches[index];
    if (historyItem.advanced)
    {
      this.router.navigateByUrl('/search/advanced/'+index);
    }
    else{
      this.router.navigateByUrl('/search/basic/'+index);
  }}
}
