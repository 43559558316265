import { Component, OnInit } from '@angular/core';
import { NotificationsService } from './services/notifications.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: [
  ]
})
export class HomeComponent implements OnInit {

  constructor(readonly notificationsService:NotificationsService) { }

  ngOnInit(): void {
  }
  sendTestNotification()
  {
    this.notificationsService.sendNotificationToSelf({someData:"This is some data"})
  }

}
