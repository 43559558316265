import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { YaraLogoComponent } from './components/yara-logo.component';
import { YaraNavBarComponent } from './navigation/yara-navbar.component';
import { AppComponent } from './app.component';

import {

  NgbToastModule
} from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';


import { AuthConfigModule } from './auth/auth-config.module';
//Configuration

import { LoggingInterceptor } from './interceptors/logging-interceptor';
import { FontAwesomeModule, FaIconLibrary, FaConfig, FaLayersCounterComponent } from '@fortawesome/angular-fontawesome';


import {
  faFloppyDisk,
  faFilePdf, faTrashAlt, faEdit, faCheck, faCircle, faCheckCircle,
  faTimes, faPlus, faSearch, faCalendarAlt, faFileExcel, faTrash, faArrowDown, faArrowUp
} from '@fortawesome/free-solid-svg-icons';
import { UserSettingsComponent } from './user-settings.component';
import { AgrochemicalsComponent } from './pages/agrochemicals/agrochemicals.component';
import { ManufacturersComponent } from './pages/master-data/manufacturers.component';
import { ChemicalCategoriesComponent } from './pages/master-data/chemical-categories.component';
import { ActiveIngredientsComponent } from './pages/master-data/active-ingredients.component';
import { CountriesComponent } from './pages/master-data/countries.component';
import { DataEntrySingle } from './pages/data-entry/data-entry-single.component';
import { SearchBasicComponent } from './pages/searches/search-basic.component';
import { ChemistsComponent } from './pages/master-data/chemists.component';
import { SearchAdvancedComponent } from './pages/searches/search.component';
import { SearchHistoryComponent } from './pages/searches/search-history.component';
import { TankmixDetailComponent } from './pages/searches/tankmix-detail.component';
import { TickCrossPipe } from './pipes/tickCross.pipe';
import { BackendAPIService } from './services/backend-api.service';
import { FormulationsComponent } from './pages/master-data/formulations.component';

import { DataEntryBulkComponent } from './pages/data-entry/data-entry-bulk.component';
import { HomeComponent } from './home.component';
import { ToastsComponent } from './components/toasts.component';
import { WidthStylePercentPipe } from './pipes/width-style-percent.pipe';
import { FaultCategoryPipe } from './pipes/fault-category.pipe';
import { NameIdSelect } from "./components/nameid-select.component";
import { UploadService } from './services/upload.service';
import { BulkFromFolderComponent } from './pages/data-entry/bulk-from-folder.component';
import { LandingComponent } from './pages/landing.component';
import { NameIdTypeaheadComponent } from './components/nameid-typeahead.component';
import { AddButtonComponent } from './components/buttons.component';
import { DeleteButtonComponent } from './components/buttons.component';
import { EditButtonComponent } from './components/buttons.component';
import { SaveButtonComponent } from './components/buttons.component';
import { CancelButtonComponent } from './components/buttons.component';
import { ClearButtonComponent } from './components/buttons.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { RequestListComponent } from './pages/requests/request-list.component';
import { RequestDetailComponent } from './pages/requests/request-detail.component';
import { RequestSearchComponent } from './pages/requests/request-search.component';
import { UpsertAgrochemicalComponent } from './pages/agrochemicals/upsert-agrochemical.component';
import { UpsertManufacturerComponent } from './pages/master-data/upsert-manufacturer.component';
import { UpsertChemicalCategoryComponent } from './pages/master-data/upsert-chemical-category.component';
import { UpsertActiveIngredientComponent } from './pages/master-data/upsert-active-ingredient.component';
import { UpsertChemistComponent } from './pages/master-data/upsert-chemist.component';
import { YaraProductsComponent } from './pages/yara-products/yara-products.component';
import { UpsertYaraProductComponent } from './pages/yara-products/upsert-yara-product.component';
import { ComponentDemoComponent } from './components/component-demo.component';
import { NameIdValueSelect } from './components/nameid-value-select.component';
import { MultiSelectNameIdComponent } from './components/multi-select-name-id.component';
import { PdfIconComponent } from './components/pdf-icon.component';
import { ResultIconComponent } from './components/result-icon.component';
import { SimpleObserverDirective } from './directives/simpleObserver';
import { StringTypeaheadComponent } from './components/string-typeahead.component';
import { YaraLogoKnowledgeGrowsComponent } from './components/yara-logo-kg.component';
import { TankmixResultTransformPipe } from './pipes/tankmix-result-transform.pipe';


export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    dateInputFormat: 'YYYY-MM-DD'
  });
}

@NgModule({
  declarations: [
        //directives
        SimpleObserverDirective,
    AppComponent,
    //Pipes
    TankmixResultTransformPipe,

    // Reuseable
    NameIdSelect,
    NameIdValueSelect,
    ToastsComponent,
    NameIdTypeaheadComponent,
    StringTypeaheadComponent,
    AddButtonComponent, 
    DeleteButtonComponent, 
    EditButtonComponent,
    ClearButtonComponent, 
    SaveButtonComponent,
    CancelButtonComponent,
    YaraLogoComponent,
    YaraLogoKnowledgeGrowsComponent,
    YaraNavBarComponent,
    PdfIconComponent,ResultIconComponent,
    // Pages
    LandingComponent,
    HomeComponent,
    FormulationsComponent,
    DataEntrySingle,
    DataEntryBulkComponent,
    BulkFromFolderComponent, 
    RequestListComponent,
    RequestDetailComponent, 
    RequestSearchComponent,
    ManufacturersComponent,
    UpsertManufacturerComponent,
    AgrochemicalsComponent,
    UpsertAgrochemicalComponent,
    ChemicalCategoriesComponent,
    UpsertChemicalCategoryComponent,
    ActiveIngredientsComponent,
    UpsertActiveIngredientComponent,
    ChemistsComponent,
    UpsertChemistComponent,
    CountriesComponent,
    SearchBasicComponent,
    SearchAdvancedComponent,
    SearchHistoryComponent,
    TankmixDetailComponent,
    UserSettingsComponent,
    // Pipes
    TickCrossPipe,
    FaultCategoryPipe,
    WidthStylePercentPipe,
    YaraProductsComponent,
    UpsertYaraProductComponent,
    ComponentDemoComponent,
    MultiSelectNameIdComponent,

  ],
  imports: [BrowserAnimationsModule,
    FormsModule, ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgbToastModule,
    AuthConfigModule,
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    TypeaheadModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.serviceWorker,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    BackendAPIService, UploadService,
     {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
    , {
      provide: HTTP_INTERCEPTORS,
      useClass: LoggingInterceptor,
      multi: true
    }, { provide: BsDatepickerConfig, useFactory: getDatepickerConfig }

  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(
    private library: FaIconLibrary, private faConfig: FaConfig
  ) {
    this.faConfig.defaultPrefix = 'fas';
    this.library.addIcons(faCircle, faCheckCircle, faCalendarAlt, faFileExcel,
      faPlus, faEdit, faTrash, faTrashAlt, faCheck, faTimes, faSearch, faFloppyDisk, faArrowDown, faArrowUp);
  }
}