import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { BackendAPIService } from "../../services/backend-api.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TankmixSearchResultDto } from "../../models/TankmixDetails";
import { faPrint, faArrowLeft,faShare,faClipboard } from "@fortawesome/free-solid-svg-icons";
import { PreferencesService } from "../../services/preferences.service";
import { tap } from "rxjs";
@Component({
  selector: "app-tankmix-detail",
  templateUrl: "./tankmix-detail.component.html",
})
export class TankmixDetailComponent implements OnInit {
  [x: string]: any;
  faPrint = faPrint;
  faArrowLeft = faArrowLeft;
  faShare = faShare;
  faClipboard=faClipboard;
  testResult: TankmixSearchResultDto;
  unitsChoice: string;
  canShare: boolean=true;
  constructor(
    private api: BackendAPIService,
    private route: ActivatedRoute,
    private preferencesService: PreferencesService,
    private location: Location
  ) {
   // this.canShare = window?.navigator?.share != null;
  }

  ngOnInit() {
    let languageCode=this.route.snapshot.params.languageCode;
    if (languageCode==null) languageCode='en';
    this.api
      .getOutcome(
        this.route.snapshot.params.tankmixId,
        languageCode
      )
      .subscribe((x) => {
        this.testResult = x;
      });
    this.preferencesService.unitsChoice$
      .pipe(
        tap((unitsChoice) => {
          this.unitsChoice = unitsChoice;
        })
      )
      .subscribe();
  }
  convertedAppRate(inp: number) {
    return this.preferencesService.convertApplicationUnits(inp);
  }
  convertedWaterRate(inp: number) {
    return this.preferencesService.convertWaterUnits(inp);
  }
  convertPowderUnits(inp: number) {
    return this.preferencesService.convertPowderUnits(inp);
  }
  back() {
    this.location.back();
  }
  share() {
    navigator.clipboard.writeText(window.location.href)
   
  }
}
