<div *ngIf="!testResult" class="testResult" i18n="@@GeneratingReport">
  Generating report ..
</div>
<div *ngIf="testResult" class="testResult">
  <yara-logo-kg [size]="1.2"></yara-logo-kg>
  <table>
    <thead>
      <tr>
        <th colspan="6" class="printReportTitle">

          <span [ngSwitch]="testResult.testBasis">
            <span *ngSwitchCase="'F'">Foliar</span>
            <span *ngSwitchCase="'S'">Seed Treatment</span>
          </span>

          <span i18n="@@TankmixInformation"> Tankmix Information</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th i18n="@@DateTested">Date Tested:</th>
        <td>{{ testResult.dateOfTest | date }}</td>
        <th i18n="@@WaterVolume">Water Volume</th>
        <td>
          {{ convertedWaterRate(testResult.waterRate) | number : "1.1-1" }}
          {{ unitsChoice }}
        </td>
      </tr>
    </tbody>
  </table>
  <table>
    <thead>
      <tr>
        <th i18n="@@Order">Order</th>
        <th i18n="@@ProductName">Product Name</th>
        <th i18n="@@Country">Country</th>
        <th>{{ unitsChoice }}</th>
        <th class="hideIfThin" i18n="@@ActiveIngredient">Active Ingredients</th>
      </tr>
    </thead>
    <tbody>
      <ng-container
        *ngFor="let ingredient of testResult.ingredients; let i = index"
      >
        <tr>
          <td>{{ i + 1 }}</td>
          <td>{{ ingredient.name }}</td>
          <td>{{ ingredient.country }}</td>
          <td>
            <span *ngIf="ingredient.form != 'P'">{{
              convertedAppRate(ingredient.rate) | number : "1.2-2"
            }}</span>
            <span *ngIf="ingredient.form == 'P'">{{
              convertPowderUnits(ingredient.rate)
            }}</span>
          </td>
          <td class="hideIfThin">{{ ingredient.activeIngredients }}</td>
        </tr>
        <tr [class]="showIfThin">
          <td></td>
          <td colspan="3">{{ ingredient.activeIngredients }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <h3 i18n="@@Result">Result</h3>
  <fa-icon
    [icon]="testResult.result | tankmixResultTransform"
    size="2x"
  ></fa-icon>
  <p>{{ testResult.phrase }}</p>
  <ng-container *ngIf="testResult.observations">
    <h3 i18n="@@Result">Observations</h3>
    <p>{{ testResult.observations }}</p>
  </ng-container>
  <h4 i18n="@@Disclaimer">Disclaimer</h4>
  <p>
    Physical tank mix tests check that the products are physically suitable for
    application with a conventional sprayer. The tests do not check for any
    adverse crop phytotoxicity or for the biological efficacy of the individual
    components when applied in a tank mix. Yara gives no warranty and accepts no
    liability in respect of physical mixability
  </p>
  <button class="hideForPrint btn" onclick="window.print()">
    <fa-icon class="white" [icon]="faPrint" size="2x"></fa-icon>
  </button>
  <button class="hideForPrint btn" (click)="back()">
    <fa-icon class="white" [icon]="faArrowLeft" size="2x"></fa-icon>
  </button>
  <span class="hideForPrint btn" (click)="share()"
    ><fa-icon class="white" [icon]="faClipboard" size="2x"></fa-icon
  ></span>
</div>
