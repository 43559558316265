<h1>Agrochemicals</h1>
<div *ngIf="agroChemicals.length">
<app-add-button id="addAgroChemical"  (click)="add()"></app-add-button>

<div *ngIf="agroChemicals.length" class="filter" [formGroup]="filterForm">
  <label for="">Name</label>
  <input id="filterName" type="text" formControlName="nameSearchText" />
  <label for="">Active</label>
  <input id="filterActive" type="text" formControlName="activeSearchText" />
  <label for="">Manufacturer</label>
  <input
    id="filterManufacturer"
    type="text"
    formControlName="manufacturerSearchText"
  />
  <app-clear-button id="filterClear" (click)="clearFilter()"></app-clear-button>
</div>
<div *ngIf="agroChemicals.length">
  <div *ngIf="!agroChemicals.length">
    <p>No data has been loaded</p>
  </div>


  <div >
    <p>
      {{ agroChemicals.length }} Agrochemicals are known.
      <span *ngIf="agroChemicals.length != filteredAgroChemicals.length"
        >{{ filteredAgroChemicals.length }} remaing after your filter was
        applied</span
      >
    </p>

    <table class="table-yara">
      <thead>
        <tr>
          <th>Name</th>
          <th>YaraVita Name</th>
          <th>Country</th>
          <th>Form</th>
          <th>In Stock</th>
          <!-- <th>Arrival Date</th>
          <th>Current</th>
          <th>Yara Product</th> -->
          <th>Manufacturer</th>
          <th>Actives</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="result-row"
          *ngFor="let agroChemical of filteredAgroChemicals; let i = index"
        >
          <td>{{ agroChemical.chemicalName }}</td>
          <td>{{ agroChemical.yaraVitaName}}</td>
          <td>{{ agroChemical.country.name }}</td>
          <td>{{ agroChemical.form.id }}</td>
          <td>
            <div class="form-check">
              <fa-icon
              icon="{{ agroChemical.isInStock | tickCross }}"
              size="1x"
            ></fa-icon>
            </div>
           
          </td>

          <!-- <td>{{ agroChemical.dateArrived | date: "dd-MMM-yyyy" }}</td>
          <td>
            <div class="form-check">
              <fa-icon
              icon="{{ agroChemical.isCurrent | tickCross }}"
              size="1x"
            ></fa-icon>
            </div>
          </td>
          <td>
            <div class="form-check">
              <fa-icon
              icon="{{ agroChemical.isYaraProduct | tickCross }}"
              size="1x"
            ></fa-icon>
            </div>
          </td> -->
          <td>{{ agroChemical.manufacturer.name }}</td>
          <td>
            <span *ngFor="let active of agroChemical.actives"
              >{{ active }} &nbsp;</span
            >
          </td>
          <td>
            <app-edit-button  (click)="edit(i)"> </app-edit-button>
             </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</div>
