import { Component, OnInit } from "@angular/core";
import { OidcSecurityService } from "angular-auth-oidc-client";

@Component({
  selector: "app-landing",
  template: `
  <div class="loginScreen">
  <div class="box">
    <img src="assets/image2vector.svg" alt="" />
    <br />
   <h1>Tankmix Administration</h1>
    <p>Please log in with your staff credentials to continue</p>
    <button
      class="btn btn-outline-success my-2 my-sm-0 me-sm-2"
      (click)="login()"
    >
      Login
    </button>
  </div>
</div>

  `,
  styles: [
    `
  .loginScreen {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/chemicals.webp");
  background-size: cover;
  .box {
    text-align: center;
    background-color: white;
    border-radius: 1rem;
    padding: 2rem;
    width: 40vw;
    position: absolute;
    right: 10vw;
    top: 2rem;
    box-shadow: 5px 5px 15px 5px #000000;
    img {
      width: 80%;
    }
  }
}

@media (max-aspect-ratio: 1) {
  .loginScreen {
    background-image: url("../../assets/chemicals.webp");

    .box {
      width: 80vw;
      left: 10vw;
      right: 10vw;
    }
  }
}
    `,
  ],
})
export class LandingComponent implements OnInit {
  constructor(private oidcSecurityService: OidcSecurityService) {}

  ngOnInit(): void {}
  login() {
    console.log("Calling this.oidcSecurityService.authorize");
    this.oidcSecurityService.authorize();
  }
}
