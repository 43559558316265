import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NameId } from 'src/app/models/name-id';
import { BackendAPIService } from 'src/app/services/backend-api.service';


@Component({
  selector: 'app-upsert-yara-product',
  templateUrl: './upsert-yara-product.component.html',
  styles: []
})
export class UpsertYaraProductComponent implements OnInit {
  yaraProductForm: FormGroup;
  yaraProductId?:string;
  countryName?: string;
  forms: NameId[] = [
    { id: "P", name: "Powder" },
    { id: "L", name: "Liquid" },
   
  ];
  countries: NameId[];
  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private api: BackendAPIService) {

      this.api.getCountries().subscribe(data => {
        this.countries = data
      }),
      
    
      this.yaraProductId = this.route.snapshot.params["yaraProductId"];
      console.log(this.yaraProductId);
      this.initForm();
      if (this.yaraProductId != null)
        this.loadYaraProductForEdit(this.yaraProductId);

        
     }
    
    get title(): string {
      if (this.yaraProductId == null) return 'Create Yara Product';
      return 'Edit Yara Product';
    }
  ngOnInit(): void {
  }

   initForm(): void {
    this.yaraProductForm = this.fb.group({
      yaraProductId: [null, Validators.required],
      yaraProductName: ['', Validators.required],
      chemicalId: ['', Validators.required],
      dhcode: ['', Validators.required],
      form: [null, Validators.required],
      country: [null, Validators.required],
      visible: [false],
      isYaraVita: [false],
      createdBy: [''],
      dateCreated: [new Date()]
    });
  }

   loadYaraProductForEdit(yaraProductId: string): void {

    this.api.getYaraRegionalProduct(yaraProductId).subscribe(data => {
       var formName = this.getNameById(data.form.id);
       data.form.name = formName,
       this.countryName = data.country.name;
console.log(data);
      this.yaraProductForm.patchValue({
        yaraProductId: data.yaraProductId,
        yaraProductName: data.yaraProductName,
        chemicalId: data.chemicalId,
        dhcode: data.dhCode,
        form: data.form,
        country: data.country,
        visible: data.visible,
        isYaraVita: data.isYaraVita,
        createdBy: data.createdBy,
        dateCreated: data.dateCreated
      });
    });
  }
  cancel() {
    this.router.navigateByUrl('yara-products');
  }
  save() {
    console.log(this.yaraProductForm.value);
    this.api.upsertYaraProduct(this.yaraProductForm.value).subscribe(() => {
      this.router.navigateByUrl('yara-products');
    });
  }
   getNameById(id: string): string | undefined {
    const foundForm = this.forms.find(form => form.id === id);
    return foundForm ? foundForm.name : undefined;
}
}
