import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DeviceService {
  deviceIsMobile: boolean;
  get isMobile() {
    return this.deviceIsMobile;
  }
  constructor() {
    if (/ipad|ios|android/i.test(window.navigator.userAgent)) {
      this.deviceIsMobile = true;
    }
  }
}
