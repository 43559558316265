import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { NameId } from 'src/app/models/name-id';
import { noop } from 'rxjs';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';


@Component({
  selector: 'app-nameid-value-select',
  template: `
  <div class="value-box" (click)="toggleActivate()" (keydown)="onKeydown($event)" tabindex="0" >{{displayText}}</div>
<div class="dropdown-items" *ngIf="activated" (mouseleave)="leave()">
  <div
    class="app-nameid-select-item"
    *ngFor="let item of items; let i = index"
    (click)="selectItem(i)" 
  >
    {{ item.name }}
</div>
</div>
  `,
  styles: [`
  .btn-sm {padding:0 0.5rem;}
  `],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => NameIdValueSelect),
  }]
})
export class NameIdValueSelect implements OnInit, ControlValueAccessor {
  activated: boolean = false;
  get displayText(): string {
    if (this.selectedItem)
      return this.selectedItem.name;
    return "Please select";
  }
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  isDisabled:boolean=false;
  @Input()
  selectedItem: NameId = {
    id: null, name: 'Please Select'
  };
  @Input()
  showBorder = true;
  @Input()
  items: NameId[] = [
    { id: null, name: 'No Data Bound' },
  ];

  constructor() { }
  ngOnInit() {
  }
  selectItem(i: number) {
    if (this.isDisabled) return;
    this.value = this.items[i].id;
    this.activated = false;
  }

  get value(): any {
    return this.selectedItem.id;
  }

  set value(v: any) {
  console.log('setvalue',v);
    if (v !== this.selectedItem.id  && this.items!=null) {
      let found=this.items.find(x=>x.id==v);
      if (found==null)
      {
        return;
      }
      this.selectedItem = found;
      this.onChangeCallback(v);
    }
  }
  toggleActivate() {
    this.activated = !this.activated;
  }
  onBlur() { this.onTouchedCallback(); }
  writeValue(v: string): void {
    
    if (v !== this.selectedItem.id && this.items!=null) {
      let found=this.items.find(x=>x.id==v);
      if (found==null)
      {
        return;
      }
      this.selectedItem = found;
     
    }
  }
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;

  }
  registerOnTouched(fn: any) { this.onTouchedCallback = fn; }

  setDisabledState?(isDisabled: boolean): void {
     this.isDisabled=isDisabled;
  }
  onKeydown(event: any) {
    if (event.keyCode == 27) {
      this.activated = false;
    }
    if (event.keyCode >= 48 && event.keyCode <= 90) {

      var index = this.items.findIndex(x => x.name.match(new RegExp('^' + event.key, 'i')));
      if (index)
        document.querySelectorAll('#country .dropdown-item')[index]?.scrollIntoView();

    }
  }
  leave() {

    this.activated = false;
  }
}
